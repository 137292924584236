import React, {useEffect, useState} from 'react';

const useMobile = () => {
    const [isMobile, setIsMobile] = useState(false)

    const mediaQuery = window.matchMedia('(max-width: 1200px)')

    function listener() {
        //console.log("resize")
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i
            .test(navigator.userAgent) || mediaQuery.matches) {
            setIsMobile( true)
            //alert("Вы используете мобильное устройство (телефон или планшет).")

        } else {
            setIsMobile( false)
            //alert("Вы используете ПК.")
        }
    }

    useEffect(()=>{
        listener()
        window.addEventListener("resize", e=>listener());

        return ()=> {
            if (window) window.removeEventListener("resize", e=>listener())
        }
    }, [])

    return isMobile
};

export default useMobile;
