import React from 'react';
import {Box} from "@material-ui/core";
import {palette, tParams} from "../../../../theme/Theme";
import Header from "../../../../theme/components/Header";
import TrueGrid from "../../../../theme/components/TrueGrid";
import useMobile from "../../../../services/hooks/useMobile";

const ProductAdvantages = (props) => {
    const {json} = props
    const isMobile = useMobile()
    if (!json?.adavantagesName || !json?.advantages || !json?.advantages?.length) return null

    return (
        <Box style={{
            background: palette.back2,
            borderRadius: tParams.bigCardRadius,
            padding: isMobile? tParams.mobileCardPadding : tParams.cardPadding,
            marginLeft: isMobile? -25: 0,
            marginRight: isMobile? -25: 0,
            marginTop: "2.5em",
        }}>
            <Header h={3} title={json?.adavantagesName} className={"mb-12"}/>
            <TrueGrid columns={2} gap={tParams.sectionGap - 10}>
                { json?.advantages?.map((el, index)=>
                    <TrueGrid template={`${isMobile? 40: 60}px auto`} gap={isMobile? 23:30} notUseMobile key={index} style={{alignItems: "start"}}>
                        <img src={el.icon} style={{width: "100%", aspectRatio: "1/1", marginTop: 5}} alt={"icon"}/>
                        <div>
                            {!!el.title &&
                                <Header h={5} title={el.title} className={"mb-2"}/>
                            }
                            <p  style={{fontSize: isMobile? 18:19}}> {el.label}</p>
                        </div>
                    </TrueGrid>
                )}
            </TrueGrid>
        </Box>
    );
};

export default ProductAdvantages;
