import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {palette, tParams} from "../../../../theme/Theme";
import {useNavigate} from "react-router-dom";
import {SERVICES_PATH} from "../../../../services/router/pages";
import SecondCard from "../../../../theme/components/SecondCard";
import TrueGrid from "../../../../theme/components/TrueGrid";
import BackgroundImg from "../../../../theme/components/BackgroundImg";
import Header from "../../../../theme/components/Header";
import {subStr} from "../../../../services/functions/functions";
import useMobile from "../../../../services/hooks/useMobile";
import {Button} from "@material-ui/core";
import {ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    card:{
        cursor: "pointer",
        transition: "0.5s",
        "&:hover": {
            color: palette.accent,
            transform: "translateY(-15px)"
        }
    },
    img: {
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: tParams.bigCardRadius,
    },
}))

const LittleNewsCard = ({item}) => {
    const content = item.attributes
    const classes = useStyles()
    const navigate = useNavigate()
    const isMobile = useMobile()
    const navHandler = () => navigate(`${SERVICES_PATH}/${content?.url}`)

    return (
        <SecondCard style={{padding: 0}} onClick={navHandler} className={classes.card}>
            <TrueGrid columns={2} gap={5}>
                <BackgroundImg item={item} style={{borderRadius: 0, backgroundSize: "contain"}}/>
                <div style={{padding:  isMobile? tParams.mobileCardPadding : tParams.cardPadding, paddingTop: isMobile? tParams.mobileCardPadding-10 : tParams.cardPadding - 5}}>
                    <Header h={isMobile? 3: 4} title={content?.head} className={"mb-7"} color={"inherit"}/>
                    {/*<FlexContainer type={"chip"}>
                        <InfoChip>
                            <IconChipContent icon={<Event/>}>
                                <b><data>{parseDateFromStr(content?.date)}</data></b>
                            </IconChipContent>
                        </InfoChip>
                    </FlexContainer>*/}
                    <p  className={"mt-5"}>{subStr(content?.text, 250)}</p>
                    <Button endIcon={<ChevronRight/>} color={"primary"} style={{margin: "15px 0px -8px -8px"}}>Подробнее</Button>
                </div>
            </TrueGrid>
        </SecondCard>
    );
};

export default LittleNewsCard;
