import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    chip:{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 5,
    }
}))


const IconChipContent = (props) => {
    const classes = useStyles();

    return (
        <div className={`${classes.chip} ${props.className}`} style={props.style}>
            {props.icon}
            <div>
                {props.children}
            </div>
        </div>
    );
};

IconChipContent.propTypes = {
    icon: PropTypes.element,

    className: PropTypes.string,
    style: PropTypes.object,
}

export default IconChipContent;