import React from 'react';
import {Route, Routes,} from "react-router-dom";

import Page404 from "./Page404";
import Home from "../../scenes/home/Home";
import Contacts from "../../scenes/contacts/Contacts";
import {
    ABOUT_PATH,
    CAREER_PATH,
    CERTS_PATH,
    CONTACTS_PATH,
    HOME_PATH,
    INTERNSHIP_PATH,
    PRODUCTS_PATH,
    SERVICES_PATH,
    VACANCY_PATH
} from "./pages";
import AboutPage from "../../scenes/about/AboutPage";
import AllServices from "../../scenes/services/allServices/AllServices";
import ServicePage from "../../scenes/services/servicePage/ServicePage";
import Career from "../../scenes/career/Career";
import Vacancies from "../../scenes/career/scenes/vacancies/Vacancies";
import OneVacancy from "../../scenes/career/scenes/oneVacancy/OneVacancy";
import Internship from "../../scenes/career/scenes/internship/Internship";
import Product from "../../scenes/products/product/Product";
import AllProducts from "../../scenes/products/allProducts/AllProducts";
import Certs from "../../scenes/about/scenes/certs/Certs";


const MyRouter = () => {
    return (
        <Routes>
            <Route path={'*'} element={<Page404/>}/>
            <Route path={HOME_PATH} element={<Home/>} index/>
            <Route path={ABOUT_PATH} element={<AboutPage/>} index/>
            <Route path={CERTS_PATH} element={<Certs/>} index/>
            <Route path={CONTACTS_PATH} element={<Contacts/>} index/>
            {/*<Route path={NEWS_PATH} element={<AllNews/>} index/>*/}
            <Route path={SERVICES_PATH} element={<AllServices/>} index/>
            <Route path={CAREER_PATH} element={<Career/>} index/>
            <Route path={VACANCY_PATH} element={<Vacancies/>} index/>
            <Route path={INTERNSHIP_PATH} element={<Internship/>} index/>
            <Route path={PRODUCTS_PATH} element={<AllProducts/>} index/>

            <Route path={`${SERVICES_PATH}/:serviceId`} element={<ServicePage/>}/>
            {/*<Route path={`${NEWS_PATH}/:newsId`} element={<OneNews/>}/>*/}
            <Route path={`${VACANCY_PATH}/:vacancyId`} element={<OneVacancy/>}/>
            <Route path={`${PRODUCTS_PATH}/:productId`} element={<Product/>}/>
        </Routes>
    );
};

export default MyRouter;