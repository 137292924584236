import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getCerts, getCertsPage} from "./reducer";
import Layout from "../../../../layout/Layout";
import PageHeader from "../../../../layout/header/PageHeader";
import {Container} from "@material-ui/core";
import TrueGrid from "../../../../theme/components/TrueGrid";
import Header from "../../../../theme/components/Header";
import CertCards from "./components/CertCards";
import Parser from "../../../../stories/parser/Parser";
import Loader from "../../../../theme/components/Loader";
import EmptyPage from "../../../../theme/components/EmptyPage";
import {tParams} from "../../../../theme/Theme";

const Certs = (props) => {

    useEffect(()=>{
        props.getCerts()
        props.getCertsPage()
    }, [])

    //console.log(props)

    const categories = props.data

    return (
        <Layout title={"Лицензии и сертификаты"} fetching={props.fetching}>
            <PageHeader title={"Лицензии и сертификаты"} center/>
            <Container>
                { props.fetching?
                    <Loader/>:
                    !categories?.length?
                        <EmptyPage/>:
                        <>
                            <Parser text={props.info?.attributes?.text}/>
                            <TrueGrid gap={tParams.sectionGap} className={`mt-15`}>
                                {categories?.length && categories?.map(el =>
                                    <div key={el.id}>
                                        <Header title={el?.attributes?.title} className={"mb-5"}/>
                                        <CertCards items={el?.attributes?.certs?.data}/>
                                    </div>
                                )}
                                {/* { vacancies?.map(el=><LittleVacancy item={el} key={el?.id}/>)}*/}
                            </TrueGrid>
                        </>
                }

            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.about.certs
});

export default connect(mapStateToProps,{
    getCerts,
    getCertsPage
})(Certs);