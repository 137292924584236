import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getProducts} from "./reducer";
import Layout from "../../../layout/Layout";
import PageHeader from "../../../layout/header/PageHeader";
import {Container} from "@material-ui/core";
import TrueGrid from "../../../theme/components/TrueGrid";
import Header from "../../../theme/components/Header";
import ProductCards from "./components/ProductCards";
import Loader from "../../../theme/components/Loader";
import EmptyPage from "../../../theme/components/EmptyPage";
import useScrollTo from "../../../services/hooks/useScrollTo";
import CategoryChip from "../../../stories/category/CategoryChip";

const AllProducts = (props) => {
    const categories = props.data
    const scroll = useScrollTo([categories?.length], "smooth")

    useEffect(()=>{
        props.getProducts()
    }, [])

    return (
        <Layout title={"Продукты"}  fetching={props.fetching}>
            <PageHeader title={"Продукты"} center/>
            <Container>
                { props?.fetching?
                    <Loader/>:
                    !categories?.length?
                        <EmptyPage/>:
                        <TrueGrid gap={80} style={{paddingBottom: 30}}>
                            {categories?.map(el =>
                                <div key={el.id}>
                                    <span  id={`#${el?.attributes?.url}`}/>
                                    <Header title={el?.attributes?.title} className={"mb-10"} icon={<CategoryChip chip={el?.attributes} onlyIcon style={{marginRight: 5}}/>}/>
                                    <ProductCards items={el?.attributes?.products?.data}/>
                                </div>
                            )}
                        </TrueGrid>
                }
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.products.products
});

export default connect(mapStateToProps,{
    getProducts
})(AllProducts);