import React from 'react';
import {Box, Card, Tooltip} from "@material-ui/core";
import {downloadFile} from "../../../../services/functions/downloadFile";
import TrueGrid from "../../../../theme/components/TrueGrid";
import Bold from "../../../../theme/components/Bold";
import {makeStyles} from "@material-ui/core/styles";
import {palette} from "../../../../theme/Theme";
import FlexContainer from "../../../../theme/components/FlexContainer";
import Header from "../../../../theme/components/Header";

const useStyles = makeStyles((theme) => ({
    card: {
        background: palette.back2,
        boxShadow: "none",
        padding: 20,
        cursor: "pointer",
        "&:hover":{
            color: palette.accent
        }
    },
    box: {
        background: palette.lightAccent,
        padding: 12,
        aspectRatio: "1/1",
        borderRadius: 15
    }
}))


const ProductFiles = (props) => {
    const classes = useStyles()

    let files = props.json?.files
    if (!files || !files?.length) return null
    return (
        <div className={"mt-7"} id={"parser"}>
            <Header h={4} title={"Центр загрузки"}/>

            <FlexContainer className={"mt-5"}>
                { files?.map(el=>
                    <Tooltip title={"Скачать"}  key={el?.url}>
                        <Card className={classes.card} onClick={()=>downloadFile(el?.url)}>
                            <TrueGrid template={"auto 1fr"} >
                                <Box className={classes.box}>
                                    <b style={{fontSize: 15, color: palette.accent}}>{el?.format}</b>
                                </Box>
                                <div>
                                    <Bold style={{color: "inherit"}}>{el?.name}</Bold>
                                    <div style={{color: "gray"}}>{el?.size}</div>
                                </div>
                            </TrueGrid>
                        </Card>
                    </Tooltip>
                )}
            </FlexContainer>
        </div>
    );
};

export default ProductFiles;
