import {combineReducers} from "redux";
import info from "./scenes/info/reducer"
import vacancies from "./scenes/vacancies/reducer";
import vacancy from "./scenes/oneVacancy/reducer"
import internship from "./scenes/internship/reducer"

export default combineReducers({
    info,
    vacancies,
    vacancy,
    internship
})