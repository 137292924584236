import {toast} from "react-toastify";
import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {call} from "../../../../services/calls/calls";
import {API_NEWS, WITH_MEDIA} from "../../../../services/const/api";
import {parseToQuery} from "../../../../services/functions/functions";

const GET_ONE_NEWS = "GET_ONE_NEWS"

const DEFAULT_STATE = {
    fetching: false,
    oneNews: {}
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_ONE_NEWS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_ONE_NEWS:
            return {
                ...state,
                fetching: false,
                oneNews: payload.data || DEFAULT_STATE.oneNews
            }

        case FAILED + GET_ONE_NEWS:
            toast.error("Не удалось получить новость")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getOneNews = (id) => call(
    'GET',
    `${API_NEWS}/${id}/${parseToQuery(WITH_MEDIA)}`,
    GET_ONE_NEWS,
)