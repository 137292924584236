import React from 'react';
import SecondCard from "../../../../../theme/components/SecondCard";
import TrueGrid from "../../../../../theme/components/TrueGrid";
import Header from "../../../../../theme/components/Header";
import {clearMarkdown, subStr} from "../../../../../services/functions/functions";
import {makeStyles} from "@material-ui/core/styles";
import {palette, tParams} from "../../../../../theme/Theme";
import {useNavigate} from "react-router-dom";
import {VACANCY_PATH} from "../../../../../services/router/pages";
import BackgroundImg from "../../../../../theme/components/BackgroundImg";
import {parseDateFromStr} from "../../../../../services/functions/time";
import FlexContainer from "../../../../../theme/components/FlexContainer";
import InfoChip from "../../../../../theme/components/InfoChip";
import IconChipContent from "../../../../../theme/components/IconChipContent";
import {ChevronRight, Event} from "@material-ui/icons";
import useMobile from "../../../../../services/hooks/useMobile";
import {Button} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card:{
        cursor: "pointer",
        transition: "0.5s",
        "&:hover": {
            color: palette.accent,
            transform: "translateY(-15px)"
        }
    },
    img: {
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: tParams.bigCardRadius,
    },
}))

const LittleVacancy = ({item}) => {
    const content = item.attributes
    const classes = useStyles()
    const navigate = useNavigate()
    const isMobile = useMobile()
    const navHandler = () => navigate(`${VACANCY_PATH}/${content?.url}`)

    return (
        <SecondCard style={{padding: 0}} onClick={navHandler} className={classes.card}>
            <TrueGrid columns={2} gap={5}>
                <BackgroundImg item={item} style={{borderRadius: 0}}/>
                <div style={{padding: isMobile? tParams.mobileCardPadding : tParams.cardPadding, paddingTop:  isMobile?  tParams.mobileCardPadding - 5 : tParams.cardPadding - 5}}>
                    <Header h={isMobile? 3:4} title={content?.head} className={"mb-2"} color={"inherit"}/>
                    <FlexContainer type={"chip"}>
                        <InfoChip style={{background: "#fff"}}>
                            <IconChipContent icon={<Event/>}>
                                <b><data>{parseDateFromStr(content?.date)}</data></b>
                            </IconChipContent>
                        </InfoChip>
                    </FlexContainer>
                    <p  className={"mt-5"}>{subStr(clearMarkdown(content?.text), 200)}</p>
                    <Button endIcon={<ChevronRight/>} color={"primary"} style={{margin: "15px 0px -8px -8px"}}>Подробнее</Button>
                </div>
            </TrueGrid>
        </SecondCard>
    );
};

export default LittleVacancy;