import React from "react";

export function parseToQuery(obj){
    if (!obj) return ""
    if (!Object.keys(obj).length) return ""

    let query= ""
    Object.entries(obj).map(([key, value])=> query+=`&${key}=${value}`)

    return query.replace("&", "?")
}

export function calcBetter(count){
    if (!count) return 0
    if (count>=1000000) return Math.round(count/1000000 *10)/10+"М"
    else if (count>=1000) return Math.round(count/1000 *10)/10+"K"
    return count
}

export function subStr(str, substr){
    if (!str) return ""
    return str.length>substr ? str.substring(0, substr-2)+"..." : str
}

export function clearMarkdown(str){
    if (!str || !str?.length) return ""
    return str.replaceAll("#", "")
}

export function getSizedMedia(item, size="large") {
    return item?.attributes?.media?.data?.attributes?.formats[size]?.url
}

export function getMedia(item) {
    return item?.attributes?.media?.data?.attributes?.url
}

export function getMediaAlt(item, size="large") {
    return item?.attributes?.media?.data?.attributes?.alternativeText
}