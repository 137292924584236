import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../../services/calls/calls";
import {API_TEXTS} from "../../../../services/const/api";
import {parseToQuery} from "../../../../services/functions/functions";

const GET_ABOUT_TEXTS = "GET_ABOUT_TEXTS"

const DEFAULT_STATE = {
    fetching: false,
    texts: []
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_ABOUT_TEXTS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_ABOUT_TEXTS:
            return {
                ...state,
                fetching: false,
                texts: payload.data
            }

        case FAILED + GET_ABOUT_TEXTS:
            toast.error("Не удалось получить страницу")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getTexts = (obj) => call(
    'GET',
    `${API_TEXTS}/${parseToQuery(obj)}`,
    GET_ABOUT_TEXTS,
)