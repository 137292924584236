import React from 'react';
import {PAGE_404} from "./pages";
import Layout from "../../layout/Layout";
import ActionCard from "../../theme/components/ActionCard";
import FlexContainer from "../../theme/components/FlexContainer";
import {useNavigate} from "react-router-dom";
import PageHeader from "../../layout/header/PageHeader";
import {Container} from "@material-ui/core";

const Page404 = () => {
    const navigate = useNavigate();

    return (
        <Layout title={PAGE_404}>
            <PageHeader title={"Ошибка 404. Страница отсутсвует"} center/>
            <Container>
                <FlexContainer type={"action"}>
                    <ActionCard
                        icon={"home"}
                        h={"На главную"}
                        text={"Переход на главную страницу"}
                        right
                        onClick={()=>navigate(`/`)}
                    />
                </FlexContainer>
            </Container>
        </Layout>
    );
};

export default Page404;