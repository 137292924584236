import React, {useEffect} from 'react';
import {useLocation} from "react-router-dom";

export function toEl(element, behavior) {
    if (element) {
        element.scrollIntoView({block: "center", behavior: behavior});
        return true
    }
    return false
}

const useScrollTo = (dep, behavior) => {
    const location = useLocation()
    let isScroll  = false

    useEffect(()=>{
        setTimeout(()=>{
            let element = document.getElementById(location.hash)
            if (element && location.hash?.length > 0) {
                //console.log("HERE!!!", document.getElementById(location.hash), dep)
                isScroll=toEl(element, behavior)
            }
        }, 300)
    }, [location.hash, ...dep])

    return isScroll
};

export default useScrollTo;