import {combineReducers} from "redux";
import about from "./scenes/about/reducer"
import partners from "./scenes/partners/reducer"
import activities from "./scenes/activities/reducer"
import certs from "./scenes/certs/reducer"

export default combineReducers({
    about,
    partners,
    activities,
    certs,
});