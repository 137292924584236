import React, {useEffect} from 'react';
import Layout from "../../layout/Layout";
import CareerHero from "./components/CareerHero";
import {connect} from "react-redux";
import {getCareerInfo} from "./scenes/info/reducer";
import CareerWhyAs from "./components/CareerWhyAs";
import {Container} from "@material-ui/core";
import CareerWork from "./components/CareerWork";
import Itog from "./components/Itog";
import Qualification from "./components/Qualification";
import "./components/styles.css"
import Pluses from "./components/Pluses";
import Loader from "../../theme/components/Loader";
import EmptyPage from "../../theme/components/EmptyPage";


const Career = (props) => {

    useEffect(()=>{
        props.getCareerInfo()
    }, [])

    const data = props.data?.attributes

    return (
        <Layout title={"Карьера"} fetching={props.fetching}>
            { props.fetching?
                <Loader/>:
                !data?
                    <EmptyPage/>:
                    <>
                        <CareerHero data={data}/>
                        <Container>
                            <CareerWhyAs data={data}/>
                            <CareerWork data={data}/>

                            <Qualification data={data}/>

                            <Pluses data={data}/>
                            {/* <Header h={2} title={data?.soutTitle} className={"mt-10 mb-7"}/>
                        <Parser text={data?.sout}/>*/}

                            <Itog data={data}/>

                            {/* <ContactForm/>*/}
                        </Container>
                    </>
            }
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.career.info
});

export default connect(mapStateToProps,{
    getCareerInfo,
})(Career);
