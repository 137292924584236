import {FAILED, REQUEST, SUCCESS} from "../../../services/const";
import {toast} from "react-toastify";
import {API_CATEGORIES, WITH_MEDIA} from "../../../services/const/api";
import {call} from "../../../services/calls/calls";
import {parseToQuery} from "../../../services/functions/functions";

const GET_PRODUCTS = "GET_PRODUCTS"

const DEFAULT_STATE = {
    fetching: false,
    data: []
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_PRODUCTS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_PRODUCTS:
            return {
                ...state,
                fetching: false,
                data: payload.data || DEFAULT_STATE.data
            }

        case FAILED + GET_PRODUCTS:
            toast.error("Не удалось получить список продуктов")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getProducts = () => call(
    'GET',
    `${API_CATEGORIES}/${parseToQuery(WITH_MEDIA)}`,
    GET_PRODUCTS,
)