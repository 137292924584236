import {combineReducers} from "redux";
import about from "../../../scenes/about/combineReducer"
import home from "../../../scenes/home/combineReducer"
import contacts from "../../../scenes/contacts/reducer"
import news from "../../../scenes/news/combineReducer"
import services from "../../../scenes/services/combineReducer"
import career from "../../../scenes/career/combineReducer"
import products from "../../../scenes/products/combineReducer"
import layout from "../../../stories/layout/combineRuducer"

export default combineReducers({
    about,
    home,
    contacts,
    news,
    services,
    career,
    products,
    layout
});
