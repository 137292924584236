import React from "react";
import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMobile from "../../services/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    boxIcon:{
        fontSize: 25,
        marginRight: 10,
        display: "flex",
        alignItems: "center",
        color: "#464646",
        //transform: "translateY(-1px)",
    },
    h: {
        ...theme.typography.bold,
        fontSize: 18,
    },
    flex:{
        display: "flex",
        alignItems: "center",
    },
    count:{
        color: "gray",
        marginLeft: 10,
        fontSize: "0.8em"
    }
}))

function getFontSize(type, isMobile){
    switch (type) {
        case 0: return isMobile? 40: 50
        case 1: return isMobile? 35: 40
        case 2: return isMobile? 30: 35
        case 3: return isMobile? 25: 30
        case 4: return isMobile? 22:25
        case 5: return isMobile? 18:20
        default: return isMobile? 25: 30
    }
}

function Header(props) {
    const classes = useStyles();
    const isMobile = useMobile()

    return(
        <Box className={`${classes.flex} ${props.className}`} style={ {...props.style }} onClick={props.onClick}>
            { props.icon && <Box className={classes.boxIcon}>{props.icon}</Box> }
            <Typography component="div" className={classes.h} style={{fontSize: getFontSize(props.h, isMobile), color: props.color ?? "#1f1f1f"}}>
                {props.title}
                {props.count? <span className={classes.count}>{props.count}</span> : null}
                {props.children}
                {props.endIcon}
            </Typography>
        </Box>
    )
}

Header.propTypes = {
    mt: PropTypes.bool,
    mb: PropTypes.bool,
    h: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    count: PropTypes.number,
    icon: PropTypes.element,
    color: PropTypes.string,
    endIcon: PropTypes.element,

    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
}

export default Header
