import React from 'react';
import Header from "../../../theme/components/Header";
import Parser from "../../../stories/parser/Parser";
import TrueGrid from "../../../theme/components/TrueGrid";
import {makeStyles} from "@material-ui/core/styles";
import {palette, tParams} from "../../../theme/Theme";
import {Box} from "@material-ui/core";
import useMobile from "../../../services/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    img:{
        width: "100%",
        aspectRatio: "1/1"
    },
    cta:{
        textAlign: "center",
        fontWeight: "bold",
        wordBreak: "break-word",
    }
}))

const Itog = ({data}) => {
    const classes = useStyles()
    const isMobile = useMobile()

    return (
        <div>
            <Box className={`mt-${tParams.spacing - 5}`} style={{
                background: palette.back2,
                borderRadius: tParams.bigCardRadius,
                padding: isMobile? tParams.mobileCardPadding : tParams.cardPadding,
                marginLeft: isMobile? -25: 0,
                marginRight: isMobile? -25: 0
            }}>
                <Header h={3} title={data?.itogTitle} className={"mb-12"}/>
                <TrueGrid columns={2} gap={tParams.sectionGap - 10}>
                    { data?.itog?.map(el=>
                        <TrueGrid template={`${isMobile? 40: 60}px auto`} gap={isMobile? 23:30} notUseMobile key={el.media} style={{alignItems: "start"}}>
                            <img src={el.media} className={classes.img}/>
                            <div>
                                <p  style={{fontSize: isMobile? 18:19}}> {el.text}</p>
                            </div>
                        </TrueGrid>
                    )}
                </TrueGrid>
            </Box>

            <div className={`${classes.cta} mt-${tParams.spacing}`} style={{fontSize: isMobile? 25:35}}>
                <Parser text={data?.itogText} style={{fontSize: "inherit"}}/>
            </div>
        </div>
    );
};

export default Itog;