export const API_PATH = '/api'
export const API_TEXTS = `${API_PATH}/texts`
export const API_JSON = `${API_PATH}/jsons`
export const API_NEWS = `${API_PATH}/posts`
export const API_SERVICES = `${API_PATH}/services`
export const API_CATEGORIES = `${API_PATH}/categories`
export const API_CERT_CATEGORIES = `${API_PATH}/cert-categories`
export const API_PRODUCTS = `${API_PATH}/products`
export const API_VACANCIES = `${API_PATH}/vacancies`
export const API_INTERNSHIP = `${API_PATH}/internship`
export const API_PARTNERS = `${API_PATH}/partners`

export const WITH_MEDIA = {populate: "*"}
export const BY_URL = "filters[url][$eq]"
