import {createStore} from 'redux'

import reducers from './combineReducers'
import middleware from './applyMiddleware';


// Add the reducer to your store on the `routing` key
export const store = createStore(
    reducers,
    middleware,
);
window.store = store