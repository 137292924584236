import moment from "moment/moment";
import "moment/locale/ru"

moment().locale('ru')

export const parseTime = (time) => moment.unix(time).format("Do MMMM YYYY, HH:mm:ss")
export const parseTimeSmall = (time) => moment.unix(time).format("HH:mm:ss, DD.MM.YY")
export const parseTimeFromStr = (time) => moment(time).format("HH:mm, DD.MM.YY")
export const parseDateFromStr = (time) => moment(time).format("Do MMMM YYYY")
export const parseDateFromStrShort = (time) => moment(time).format("DD.MM.YY")

export const formatTime = (time) => moment.unix(time).format("YYYY-MM-DD");
export const formatDate = (time) => moment.unix(time).format("DD.MM.YY");
