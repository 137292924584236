import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {getPartners} from "./reducer";
import Header from "../../../../theme/components/Header";
import Loader from "../../../../theme/components/Loader";
import Img from "../../../../theme/components/Img";
import Bold from "../../../../theme/components/Bold";
import {makeStyles} from "@material-ui/core/styles";
import {palette, tParams} from "../../../../theme/Theme";
import EmptyPage from "../../../../theme/components/EmptyPage";
import ScrollContainer from "react-indiana-drag-scroll";
import "./animation.css"
import Marquee from "react-fast-marquee";

const useStyles = makeStyles((theme) => ({
    scrollContainer:{
        display: "flex",
        gap: 25,
        paddingBottom: 30,
        cursor: "grab",
        '&::-webkit-scrollbar': {
            background: "#f2f6fb", borderRadius: "100px", height: 10, width: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: "100px", background: palette.lightAccent,
        },
    },
    card: {
        scrollSnapAlign: "start",
        width: 200,
        height: "fit-content",
        "&:hover":{
            color: palette.accent,
        }
    },
    name: {
        textAlign: "center",
        marginTop: 15,
        color: "inherit"
    },
}))

const Partner = ({partner, el}) => {
    const classes = useStyles()
    return (
        <a href={partner?.link} target={"_blank"} className={classes.card}>
            <div className={classes.card}>
                <Img item={el} style={{maxHeight: 100, objectFit: "contain"}}/>
                <Bold className={classes.name}>{partner?.name}</Bold>
            </div>
        </a>
    )
}

const Partners = (props) => {
    const classes = useStyles()
    const ref = useRef()

    useEffect(()=>{
        props.getPartners()
    }, [])

    useEffect(()=>{
        function listener() {
            const el = document.querySelector(".marquee")
            console.log(el)
            if (el) {
                setInterval(() => {
                    el.scrollLeft += 150
                    console.log(el?.scrollLeft)
                }, 500)
            }
        }
        listener()

        window.addEventListener("load", listener);
        return (
            ()=>window.removeEventListener("load", listener)
        )
    }, [ref])

    return (
        <section className={`mt-${tParams.spacing}`}>
            <Header h={1} title={"Нам доверяют"} style={{justifyContent: "center"}} className={"mb-18"}/>
            <span id={"#partners"}/>
            { props.fetching?
                <Loader/>:
                !props.partners?.length?
                    <EmptyPage/>:
                        <ScrollContainer horizontal={true} className={`${classes.scrollContainer}`} hideScrollbars={false} >
                            { props.partners?.map(el =>
                                <Partner key={el.id} partner={el?.attributes} el={el} ref={ref}/>
                            )}
                        </ScrollContainer>

                   /* <div className={classes.scrollContainer}>
                        { props.partners?.map(el =>
                            <Partner key={el.id} partner={el?.attributes} el={el}/>
                        )}
                    </div>*/
            }
        </section>
    );
};

const mapStateToProps = (state) => ({
    ...state.about.partners
});

export default connect(mapStateToProps,{
    getPartners
})(Partners);
