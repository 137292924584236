import { Box } from "@material-ui/core";

const Video = ({ title, src }) => {
  return (
    <div>
      <iframe
        className="border-radius-8"
        title={title}
        src={src}
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
        style={{ border: "none", width: "100%", aspectRatio: "16 / 9" }}
      />
      <p className="text-small font-semibold">{title}</p>
    </div>
  );
};

export const ProductOverviewVideos = ({ videos, ...props }) => {
  return (
    <Box className="flex-column gap-24" {...props} >
      {videos.map((video, index) => (
        <Video src={video.url} key={index} title={video.title} />
      ))}
    </Box>
  );
};
