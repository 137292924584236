import React from 'react';
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";
import {palette} from "../../theme/Theme";
import {makeStyles} from "@material-ui/core/styles";
import {ChevronRight, Home} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    home: {
        cursor: "pointer",
        "&:hover": {
            color: palette.accent,
        }
    },
    theme: {
        //fontFamily: hFont,
        cursor: "pointer",
        textDecoration: "underline",
        "&:hover": {
            color: palette.accent,
        }
    }
}))

const Breadcrumbs = (props) => {
    const classes = useStyles()
    const navigate = useNavigate()
    const pages = props.pages

    return (
        <div className={`flex items-center ${props.className}`} style={{gap: 5, marginBottom: 5, marginTop: 5, ...props.style}}>
            <Home className={classes.home} onClick={()=>navigate("/")}/>
            {pages?.map(el=>
                <React.Fragment key={el.name}>
                    <ChevronRight/>
                    <div onClick={()=>navigate(el.link)} className={classes.theme}>{el.name}</div>
                </React.Fragment>
            )}
            <ChevronRight/>
            {props.current}
        </div>
    );
};

Breadcrumbs.propTypes = {
    pages: PropTypes.array.isRequired,
    current: PropTypes.string.isRequired,

    className: PropTypes.string,
    style: PropTypes.object
}

export default Breadcrumbs;