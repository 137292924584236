import React from 'react';
import {
    ABOUT_PATH,
    CAREER_PATH,
    CERTS_PATH,
    CONTACTS_PATH,
    HOME_PATH,
    INTERNSHIP_PATH,
    PRODUCTS_PATH,
    SERVICES_PATH,
    VACANCY_PATH
} from "../../services/router/pages";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "./MenuItem";
import Events from "./Events";

const useStyles = makeStyles((theme) => ({
    mobileNav:{
        display: "flex",
        flexDirection: "column",
        gap: 5,
        alignItems: "flex-start",
    },
}))

const MainMenu = ({isMobile, openHandler, eventsRef}) => {
    const classes = useStyles()

    const careerPages  = [
        {"link": CAREER_PATH, name: "Информация о карьере"},
        {"link": VACANCY_PATH, name: "Вакансии"},
        {"link": INTERNSHIP_PATH, name: "Стажировка"}
    ]

    const aboutPages = [
        {"link": `${ABOUT_PATH}#about`, name: "Информация о компании"},
        {"link": `${ABOUT_PATH}#partners`, name: "Заказчики и партнеры"},
        {"link": `${ABOUT_PATH}#activities`, name: "Виды деятельности"},
        {"link": CERTS_PATH, name: "Лицензии и сертификаты"}
    ]

    const Item = ({to, title, pages}) => <MenuItem title={title} to={to} openHandler={openHandler} pages={pages}/>

    return (
        <nav className={isMobile? classes.mobileNav : ""}>
            <Item title="Главная" to={HOME_PATH} />
            <Item title="О компании" to={ABOUT_PATH} pages={aboutPages}/>
            <Item title="Продукты" to={PRODUCTS_PATH}/>
            <Item title="Услуги" to={SERVICES_PATH}/>
            <Item title="Карьера" to={CAREER_PATH} pages={careerPages}/>
            {/*<Item title="Новости" to={NEWS_PATH}/>*/}
            <Item title="Контакты" to={CONTACTS_PATH}/>

            <Events openHandler={openHandler} eventsRef={eventsRef}/>
        </nav>
    );
};

export default MainMenu;