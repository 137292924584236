import React from 'react';
import {Box} from "@material-ui/core";
import {PROJECT_NAME} from "../services/router/pages";
import {makeStyles} from "@material-ui/core/styles";
import TopLoader from "../stories/layout/topLoader/TopLoader";

const useStyles = makeStyles((theme) => ({
    page:{
        width: "100%",
        height: "100%",
        background: "#fff",
        ...theme.customScrollY,
    }
}))

const Layout = (props) => {
    document.title = `${PROJECT_NAME} - ${props.title}`
    const classes = useStyles()

    return (
        <>
            <TopLoader fetching={props.fetching}/>
            <Box className={"mt-2"} style={{overflowX: "hidden"}}>
                {props.children}
            </Box>
        </>
    );
};

export default Layout;