import React, {useState} from 'react';
import {Box, Button} from "@material-ui/core";
import Header from "../../../theme/components/Header";
import TrueGrid from "../../../theme/components/TrueGrid";
import {makeStyles} from "@material-ui/core/styles";
import career from "../../../theme/assets/img/career.gif";
import {palette, tParams} from "../../../theme/Theme";
import Img from "../../../theme/components/Img";
import useMobile from "../../../services/hooks/useMobile";
import InfoChip from "../../../theme/components/InfoChip";
import FlexContainer from "../../../theme/components/FlexContainer";
import PcBottomShit from "../../../theme/components/PcBottomShit";
import {Visibility} from "@material-ui/icons";
import Bold from "../../../theme/components/Bold";

const useStyles = makeStyles((theme) => ({

}))

const CareerWork = ({data}) => {
    const classes = useStyles()
    const isMobile = useMobile()
    const [open, setOpen] = useState(false)

    return (
        <Box className={`mt-${tParams.spacing+3}`}>
            <Header h={2} title={data?.workTitle} className={"mb-5 justify-center"}/>
            <div>
                <Img src={career} maxHeight={350} style={{maxWidth: 700, display: "block", margin: "auto"}}/>
            </div>

            <FlexContainer className={isMobile? "mt--10":"mt-12"} style={{justifyContent: "center"}}>
                { data?.work?.map((el, index)=>
                    <InfoChip style={{padding: "5px 20px"}} key={index}>
                        <Bold style={{fontSize: 18}}>{el.head}</Bold>
                    </InfoChip>
                )}
                <Button onClick={() => setOpen(true)} color={"primary"} style={{borderRadius: 50, padding: "5px 25px", background: palette.lightAccent}} startIcon={<Visibility/>}>Подробнее</Button>
            </FlexContainer>

            <PcBottomShit open={open} setOpen={setOpen}>
                <TrueGrid gap={40}>
                    { data?.work?.map((el, index)=>
                        <div key={index}>
                            <Header h={4} title={el.head} className={"mb-4"}/>
                            <p>{el?.text}</p>
                        </div>
                    )}
                </TrueGrid>
            </PcBottomShit>
        </Box>
    );
};

export default CareerWork;