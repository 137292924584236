import React from 'react';
import {Card, Container} from "@material-ui/core";
import {palette} from "../../../theme/Theme";
import {makeStyles} from "@material-ui/core/styles";
import Header from "../../../theme/components/Header";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    card:{
        //marginTop: 50,
        boxShadow: "none",
        border: "3px solid " + palette.accent,
        //background: palette.accent,
        borderRadius: 30,
        padding: "30px 50px",
        //background: palette.lightAccent,
    },
}))


const ContactForm = () => {
    const classes = useStyles()

    const submitHandler = (e) =>{
        //e.preventDefault()
        toast.info("Отправка в разработке")
    }

    return (
        <Container className={"mt-15 p-0"}>
            <Card className={classes.card}>
                <div className={"flex justify-center"}>
                    <Header title={"Расскажите о себе"} color={palette.accent}/>
                </div>
                <p className={"mt-5 text-center"}>
                    Присылайте свое резюме на почтовый адрес нашего HR-менеджера: <a style={{color: palette.accent}} href={"mailto:hr@neobit.ru"}>hr@neobit.ru</a>.
                </p>
               {/* <TrueGrid columns={2} className={"mt-7"} gap={50}>
                    <div>
                        <p>Возможно, на сайте отсутсвует подходящая вакансия или у вас есть вопросы.</p>
                        <p className={"mt-5"}>Оставьте данные о себе и мы свяжемся в ближайшее время.</p>
                        <p className={"mt-5"}>
                            Либо можно написать нам на почту <a style={{color: palette.accent}} href={"mailto:hr@neobit.ru"}>hr@neobit.ru</a>.
                        </p>
                    </div>
                    <form className={"mt--3"}>
                        <TrueGrid>
                            <TextField label={"Имя"} fullWidth size={"small"}/>
                            <TextField label={"Немного о себе"} fullWidth size={"small"} multiline rows={4} placeholder={"Можно перечислить свои навыки или рассказть о недавних проектах"}/>
                            <TextField label={"Ссылка на резюме"} fullWidth size={"small"}/>
                            <TextField label={"Контакт для связи"} fullWidth size={"small"} placeholder={"Почта, мессенджер или телефон"}/>
                            <Button color={"primary"} startIcon={<Check/>} variant={"contained"} style={{width: "fit-content"}} size={"large"} onClick={e=>submitHandler(e)}>Отправить</Button>
                        </TrueGrid>
                    </form>
                </TrueGrid>*/}
            </Card>
        </Container>
    );
};

export default ContactForm;