import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import useMobile from "../../services/hooks/useMobile";

const useStyles = makeStyles(theme => ({
    grid:{
        display: "grid",
        gridTemplateColumns: "1fr",
        columnGap: "20px",
        rowGap:"20px",
    }
}))


const TrueGrid = (props) => {
    const classes = useStyles()
    const isMobile = useMobile()
    let columns = props.columns

    if (isMobile && !props.notUseMobile)  columns = 1

    return (
        <div className={`${classes.grid} ${props.className}`}
             style={{
                 columnGap: props.gap,
                 rowGap:  props.gap,
                 alignItems: props.alignItems,
                 gridTemplateColumns: props.template? props.template : `repeat(${columns ?? 1}, ${props.columnWidth ?? "1fr"})`,
                 ...props.style,
             }}
        >
            {props.children}
        </div>
    );
};

TrueGrid.propTypes = {
    gap: PropTypes.number,
    template: PropTypes.string,
    columns: PropTypes.number,
    columnWidth:  PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    alignItems: PropTypes.string,
    notUseMobile: PropTypes.bool,

    className: PropTypes.string,
    style: PropTypes.object
}

TrueGrid.defaultProps = {
    alignItems: "auto",
}

export default TrueGrid;