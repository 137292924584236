import React from 'react';
import PropTypes from "prop-types";
import {Card} from "@material-ui/core";
import {palette, tParams} from "../Theme";

const SecondCard = (props) => {
    return (
        <Card {...props}
              className={props.className}
              style={{background: palette.back2, boxShadow: "none", borderRadius:  tParams.bigCardRadius, ...props.style}}
        >
            {props.children}
        </Card>
    );
};

SecondCard.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object
}

export default SecondCard;