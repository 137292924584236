import {useEffect, useState} from 'react'
import PropTypes from "prop-types";
import "./style.css"

const FADE_INTERVAL_MS = 3500

const AnimatedText = ({words}) => {
    const [fadeProp, setFadeProp] = useState('fadein' )
    const [wordOrder, setWordOrder] = useState(0)

    useEffect(() => {
        const wordTimeout = setInterval(() => {
            setWordOrder((prevWordOrder) => (prevWordOrder + 1) % words.length)

        }, FADE_INTERVAL_MS)

        return () => clearInterval(wordTimeout)
    }, [])

    return (
        <span className={fadeProp}>{words[wordOrder]}</span>
    )
}

AnimatedText.defaultProps = {
    words: []
}


AnimatedText.propTypes = {
    words: PropTypes.array.isRequired
}

export default AnimatedText;