import React, {useEffect, useRef} from 'react';
import {Box, Container, Grid} from "@material-ui/core";
import Header from "../../../../theme/components/Header";
import {makeStyles} from "@material-ui/core/styles";
import cyber from "../../../../theme/assets/img/cyber.gif"
import cyberVideo from "../../../../theme/assets/img/heroVidCut.mp4"
import FlexContainer from "../../../../theme/components/FlexContainer";
import {connect} from "react-redux";
import {getHero} from "./reducer";
import useMobile from "../../../../services/hooks/useMobile";
import CategoryChip from "../../../../stories/category/CategoryChip";
import AnimatedText from "../../../../stories/animations/AnimatedText";
import heroGif from "../../img/heroGif2.gif"

const useStyles = makeStyles((theme) => ({
    hero:{
        height: "80vh",
        maxHeight: 900,
    },
    mobileHero:{
      paddingTop: 50,
      paddingBottom: 50,
    },
}))

const Hero = (props) => {
    const isMobile = useMobile()
    const classes = useStyles()
    const ref= useRef(null)

    const TEXTS = [
        isMobile? "кибер-безопасности":"кибербезопасности",
        "защиты информации",
        "тестирования",
        "сертификации",
    ];

    useEffect(()=>{
        props.getHero()
        //ref.current.play()
    }, [])

    return (
        <Container>
            <Grid container spacing={3} className={isMobile? classes.mobileHero: classes.hero}>
                <Grid item lg={6} className={"flex items-start flex-column justify-center"} style={isMobile? {}:{marginTop: -120}}>
                    {isMobile ?
                        <Header h={1} title={<>Передовые решения в области <div style={{minHeight: 105}}><AnimatedText words={TEXTS}/></div> </>} style={{wordWrap: "break-word"}}/> :
                        <>
                            <Header h={0} title={"Передовые решения"}/>
                            <Header h={0} title={"в области"}/>
                            <Header h={0} title={<AnimatedText words={TEXTS}/>}/>
                        </>
                    }
                </Grid>

                <Grid item lg={6} >
                   <img src={heroGif}
                        style={{
                            display: "flex",
                            margin: "auto",
                            objectFit: "contain",
                            aspectRatio: "1/1",
                            height: isMobile? "auto":"100%",
                            maxHeight: "70vh",
                            filter: "contrast(1.1)",
                            width: isMobile? "100%": "auto",
                            marginTop: isMobile? "-30px": "auto",
                            zIndex: 0,
                            position: "relative"
                   }}
                   />
                   {/* <video ref={ref} autoPlay muted loop
                           style={{
                               display: "flex",
                               margin: "auto",
                               objectFit: "contain",
                               aspectRatio: "1/1",
                               height: isMobile? "auto":"100%",
                               maxHeight: "70vh",
                               filter: "contrast(1.05)",
                               width: isMobile? "100%": "auto",
                               marginTop: isMobile? "-30px": "auto",
                               zIndex: 0,
                               position: "relative"
                    }}
                    >
                        <source src={cyberVideo} type={"video/mp4"}/>
                    </video>*/}
                </Grid>
            </Grid>

            <Box style={{marginTop: isMobile? -40: -200, display: "grid", gap: "40px", zIndex: 1, position: "relative"}}>
                {props.chips?.map(el=>
                    <div key={el.title}>
                        <Header h={3} title={el.title} className={"mb-4"}/>
                        <FlexContainer gap={30}>
                            {el.entry?.map(chip=> <CategoryChip chip={chip} key={chip?.title}/>)}
                        </FlexContainer>
                    </div>
                )}
            </Box>
        </Container>
    );
};

const mapStateToProps = (state) => ({
    ...state.home.hero
});

export default connect(mapStateToProps,{
    getHero
})(Hero);
