export const PROJECT_NAME = "НеоБИТ"
export const PAGE_404 = "Ошибка 404. Страница отсутсвует"

export const HOME_PATH = "/"
export const ABOUT_PATH = "/about"
export const CERTS_PATH = `${ABOUT_PATH}/certs`
export const CONTACTS_PATH= "/contacts"
export const NEWS_PATH= "/news"
export const SERVICES_PATH = "/services"
export const CAREER_PATH = "/career"
export const PRODUCTS_PATH = "/products"
export const VACANCY_PATH = `${CAREER_PATH}/vacancies`
export const INTERNSHIP_PATH = `${CAREER_PATH}/internship`