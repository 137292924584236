import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {hFont, palette, tParams} from "../../../../theme/Theme";
import TrueGrid from "../../../../theme/components/TrueGrid";
import Header from "../../../../theme/components/Header";
import {getTexts} from "./reducer";
import {connect} from "react-redux";
import SecondCard from "../../../../theme/components/SecondCard";
import BackgroundImg from "../../../../theme/components/BackgroundImg";
import Loader from "../../../../theme/components/Loader";
import useMobile from "../../../../services/hooks/useMobile";
import {WITH_MEDIA} from "../../../../services/const/api";
import EmptyPage from "../../../../theme/components/EmptyPage";
import TopLoader from "../../../../stories/layout/topLoader/TopLoader";

const useStyles = makeStyles((theme) => ({
    p:{
        fontSize: 18,
        fontFamily: hFont,
        whiteSpace: "pre-line"
    },
    grid: {
        alignItems: "start",
    },
}))

const About = (props) => {
    const classes = useStyles()
    const isMobile = useMobile()

    useEffect(()=>{
        props.getTexts({
            "sort[0]":"id:asc",
            ...WITH_MEDIA
        })
    }, [])

    return (
        <TrueGrid gap={50}>
            {  props.fetching?
                <Loader/>:
                    !props.texts?.length?
                        <EmptyPage/>:
                        props.texts?.map((text, index) =>
                            <SecondCard style={{padding: 0}} key={index}>
                                <TrueGrid columns={2} className={classes.grid} gap={5} key={index} style={{direction: index%2? "ltr" : "rtl"}}>
                                    <BackgroundImg item={text} style={{borderRadius: 0, backgroundSize: "contain"}}/>
                                    <div style={{direction: "ltr", padding: isMobile? tParams.mobileCardPadding: tParams.cardPadding, paddingTop: isMobile? tParams.mobileCardPadding-10: tParams.cardPadding-5}}>
                                        <Header h={3} title={text?.attributes?.head} className={"mb-6"}/>
                                        <p className={classes.p}>{text?.attributes?.text}</p>
                                    </div>
                                </TrueGrid>
                            </SecondCard>
                        )
            }
        </TrueGrid>
    );
};

const mapStateToProps = (state) => ({
    ...state.about.about
});

export default connect(mapStateToProps,{
    getTexts
})(About);
