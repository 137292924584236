import {toast} from "react-toastify";
import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {call} from "../../../../services/calls/calls";
import {API_NEWS, WITH_MEDIA} from "../../../../services/const/api";
import {parseToQuery} from "../../../../services/functions/functions";

const GET_ALL_NEWS = "GET_ALL_NEWS"

const DEFAULT_STATE = {
    fetching: false,
    news: []
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_ALL_NEWS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_ALL_NEWS:
            return {
                ...state,
                fetching: false,
                news: payload.data || DEFAULT_STATE.news
            }

        case FAILED + GET_ALL_NEWS:
            toast.error("Не удалось получить новости")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getNews = () => call(
    'GET',
    `${API_NEWS}/${parseToQuery(WITH_MEDIA)}`,
    GET_ALL_NEWS,
)