import React from 'react';
import {Container, IconButton, Tooltip} from "@material-ui/core";
import {BottomSheet} from "react-spring-bottom-sheet";
import {Close} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import 'react-spring-bottom-sheet/dist/style.css'

const useStyles = makeStyles((theme) => ({
    scroll: {
        maxHeight: "calc(100vh - 200px)",
        overflowY: "scroll",
        ...theme.customScrollY,
        paddingBottom: 20,
    }
}))

const PcBottomShit = (props) => {
    const classes = useStyles();

    return (
        <BottomSheet open={props.open} scrollLocking={false}>
            <Container>
                <div className={"flex"}>
                    <IconButton onClick={()=>props.setOpen(false)} style={{marginLeft: "auto"}}><Close/></IconButton>
                </div>
            </Container>
            <div className={classes.scroll}>
                <Container >
                    { props.children}
                </Container>
            </div>
        </BottomSheet>
    );
};

export default PcBottomShit;
