import React, {useRef} from 'react';
import {Box, Container} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import logo from "../../theme/assets/img/logo.png"
import useMobile from "../../services/hooks/useMobile";
import MainMenu from "./MainMenu";
import OpenMenuButton from "./OpenMenuButton";
import {Link} from "react-router-dom";
import {HOME_PATH} from "../../services/router/pages";

const useStyles = makeStyles((theme) => ({
    menuBar: {
        background: "rgba(255,255,255,0.85)",
        backdropFilter: "blur(8px)",
        position: "sticky",
        zIndex: 2,
        top: 0,
    },
    logo:{
        width: 150,
    },
}))

const SiteHeader = ({scrollRef}) => {
    const classes = useStyles()
    const ref = useRef(null)
    const isMobile = useMobile()

    const handleScroll = () => {
        let scroll =  scrollRef.current.scrollTop

        //if (scroll>0)  ref.current.style.boxShadow = "0px 3px 20px 0px rgba(0, 00, 80, 0.07)"
        //else ref.current.style.boxShadow = "none"
    };

    React.useEffect(() => {
        scrollRef.current && scrollRef.current.addEventListener("scroll", handleScroll);
        return () =>  {
            if (scrollRef?.current)
            scrollRef.current?.removeEventListener("scroll", handleScroll);
        }
    }, []);

    return (
        <div ref={ref} className={classes.menuBar}>
            <Container style={{padding: isMobile?  "13px 25px" : 15}}>
                <Box className={"flex justify-between items-center"}>
                    <Link to={HOME_PATH}>
                        <img src={logo} className={classes.logo}/>
                    </Link>

                    { isMobile ?
                        <OpenMenuButton scrollRef={scrollRef}/>:
                        <MainMenu/>
                    }

                </Box>
            </Container>
        </div>
    );
};

export default SiteHeader;
