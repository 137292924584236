import {FAILED, REQUEST, SUCCESS} from "../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../services/calls/calls";
import {API_PATH} from "../../../services/const/api";

const GET_FOOTER = "GET_FOOTER"

const DEFAULT_STATE = {
    fetching: false,
    data: {}
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_FOOTER:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_FOOTER:
            return {
                ...state,
                fetching: false,
                data: payload.data || DEFAULT_STATE.data
            }

        case FAILED + GET_FOOTER:
            toast.error("Не удалось получить футер")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getFooter = () => call(
    'GET',
    `${API_PATH}/footer`,
    GET_FOOTER,
)