import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {palette} from "../../../../../theme/Theme";
import TrueGrid from "../../../../../theme/components/TrueGrid";
import {Box, Card, Tooltip} from "@material-ui/core";
import {PictureAsPdf} from "@material-ui/icons";
import {downloadFile} from "../../../../../services/functions/downloadFile";

const useStyles = makeStyles((theme) => ({
    card: {
        background: palette.back2,
        boxShadow: "none",
        padding: 20,
        cursor: "pointer",
        "&:hover":{
            color: palette.accent
        }
    },
    box: {
        background: palette.lightAccent,
        padding: 12,
        aspectRatio: "1/1",
        borderRadius: 15
    }
}))

const CertCards = ({items}) => {
    const classes = useStyles()
    if (!items?.length) return <p>Сертификаты в данной категории отстуствуют</p>
    return (
        <TrueGrid columns={3}>
            { items?.map(el=>
                <Tooltip title={"Скачать"} key={el?.attributes?.fileUrl}>
                    <Card className={classes.card} onClick={()=>downloadFile(el?.attributes?.fileUrl)}>
                        <TrueGrid template={"auto 1fr"} >
                            <Box className={classes.box}>
                                <PictureAsPdf color={"primary"} style={{fontSize: 35}}/>
                            </Box>
                            <div style={{color: "inherit"}}>{el?.attributes?.description}</div>
                        </TrueGrid>
                    </Card>
                </Tooltip>
            )}
        </TrueGrid>
    );
};

export default CertCards