import React from 'react';
import Header from "../../../theme/components/Header";
import Img from "../../../theme/components/Img";
import Parser from "../../../stories/parser/Parser";
import {tParams} from "../../../theme/Theme";

const Pluses = ({data}) => {
    return (
        <div>
            <Header h={2} title={data?.plusesTitle} className={`mt-${tParams.spacing} mb-10 justify-center`}/>
            <Img src={data?.plusesMedia?.data?.attributes?.url} maxHeight={600} className={"mb-14"} style={{objectFit: "contain"}}/>
            <div id={"pluses"}>
                <Parser text={data?.pluses}/>
            </div>

        </div>
    );
};

export default Pluses;
