import React, {useEffect, useRef} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import SiteHeader from "./header/SiteHeader";
import Footer from "./Footer";
import {ToastContainer} from "react-toastify";
import {useLocation} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    page:{
        width: "100%",
        height: "100%",
        background: "#fff",
        ...theme.customScrollY,
    }
}))

const SiteContainer = (props) => {
    const classes = useStyles()
    const scrollRef = useRef(null)
    const location = useLocation()

    useEffect(()=>{
        scrollRef.current.scrollTo(0,0)
    }, [location.pathname])

    return (
        <Box className={classes.page} id={"site-scroll"} ref={scrollRef}>
            <SiteHeader scrollRef={scrollRef}/>

            {props.children}

            <Footer/>

            <ToastContainer autoClose={3005}/>
        </Box>
    );
};

export default SiteContainer;