import React from 'react';
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    h:{
        ...theme.typography.bold
    }
}))

const Bold = (props) => {
    const classes = useStyles();

    return (
        <Typography component={props.component? props.component : "div"}  className={`${classes.h} ${props.className}`} style={props.style}>{props.children}</Typography>
    );
};

Bold.propTypes = {
    component: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
}

export default Bold;