import React from 'react'
import {MuiThemeProvider} from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {createTheme} from "@material-ui/core";
import "yet-another-react-lightbox/styles.css";

const input = {
    background: "#edeef0",
    borderRadius: 10,
    borderWidth: "0px",
}

export const palette = {
    back: "#fff",
    back2: "#f6f6f6",
    accent: "#ee3f45",
    lightAccent: "#ffdbdf",

    green: "#00ca1f",
    warning: "#ffb600",
    error: "#f44336",

    border: "1px solid lightgray",
    chipShadow: "0px 5px 12px 0px rgba(0, 00, 80, 0.15)",
    shadow: "rgba(0, 0, 0, 0.06) 0px 10px 36px 8px",
}

export const tParams = {
    bigCardRadius: 30,
    spacing: 22,

    cardPadding: 55,
    mobileCardPadding: 45,
    sectionGap: 70,
}

export const hFont = "Grotesque, sans-serif"

const theme = createTheme({
    typography: {
        fontSize: 14,
        body1: {
            fontSize: '14px',
        },
        bold:{
            fontFamily: hFont,
            fontWeight: 700,
            color: "#1f1f1f",
        },
    },

    palette: {
        primary: {
            light: palette.lightAccent,
            main: palette.accent,
            //dark: '#1661c7',
            contrastText: '#fff',
        },
        secondary:{
            main: "#1877F2",
        },
        back: palette.back,
        back2: palette.back2,
    },

    cancel: "red",

    customScrollX:{
        overflowX: "auto",
        scrollSnapType: "x mandatory",
        '&::-webkit-scrollbar': {
            background: "#f2f6fb", borderRadius: "100px", height: 10, width: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: "100px", background: palette.lightAccent,
        },
    },

    customScrollY:{
        overflowY: "auto",
        '&::-webkit-scrollbar': {
            width: 5, height: 5,
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: "100px", background: palette.lightAccent,
        },
    },

    overrides: {
        MuiButton: {
            root: {
                textTransform: 'none',
                fontWeight: 700,
                fontSize: 15,
                borderRadius: 50,
                fontFamily: hFont,
            },
            contained:{
                boxShadow: "none",
            }
        },
        MuiContainer: {
            root: {
                paddingLeft: 25,
                paddingRight: 25
            }
        },
        MuiCard: {
            root: {
                borderRadius: '20px',
                padding: "25px 25px",
                //background: palette.back2,
                boxShadow: palette.shadow,
                animation: "base 0.5s",
            },
        },
        MuiOutlinedInput:{
            root: {
                ...input
            },
            notchedOutline: {
                borderWidth: 0,
            },
        },
        MuiDialog:{
            paper:{
                borderRadius: 15,
                padding: 10,
            }
        },
        MuiSelect:{
            root:{
                display: "flex",
                alignItems: "center",
                maxHeight: 15,
            }
        },

        //Table
        MuiTableHead:{
            root:{
                background: palette.back2,
            }
        },
        MuiToolbar:{
            root:{
                background: palette.back2,
            }
        },
        MuiTableCell: {
            head: {
                fontSize: '14px',
                padding: '10px 12px',
                fontFamily: hFont,
                fontWeight: 600,
            },
            root: {
                fontSize: '14px',
                //whiteSpace: 'pre-wrap',
                //wordBreak: 'break-all',
                padding: 12,
            },
        },
        MUIDataTableHeadCell:{
            fixedHeader:{
                backgroundColor: palette.back2,
            },
            toolButton: {
                fontSize: '14px', fontWeight: 600,
            }
        },
        MUIDataTableSelectCell:{ headerCell:{ backgroundColor: palette.back2,} }
        //EndTable
    },
});

const Theme = (props) => {
    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            {props.children}
        </MuiThemeProvider>
    )
}

export default Theme
