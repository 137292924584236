import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from "react-router-dom";

const useLightbox = props => {
    const [openLightbox, setOpenLightbox] = useState(false)
    const [slides, setSlides] = useState([])

    const navigate = useNavigate()
    const location = useLocation()
    useEffect(()=>{
       // console.log(location)
        function listener() {
            let hash = location.hash
            if (!hash) return
            hash = hash.replace("#", "")
            if (hash?.length)
                addSlide(hash)
        }
        listener()
    }, [location])

    function addSlide(url) {
        setSlides([...slides, {src: url}])
        setOpenLightbox(true)
    }

    function closeBox () {
        navigate("", {replace: true})
        setSlides([])
        setOpenLightbox(false)
    }

    return {openLightbox, slides, addSlide, closeBox}
};

useLightbox.propTypes = {

};

export default useLightbox;
