import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    chip:{
        padding: "3px 10px",
        borderRadius: 50,
        background: "#f0f2f5",
        display: "flex",
        alignItems: "center",
        gap: 5,
    }
}))


const InfoChip = (props) => {
    const classes = useStyles();

    return (
        <span className={`${classes.chip} ${props.className}`} style={props.style}>
            {props.icon}
            {props.children}
        </span>
    );
};

InfoChip.propTypes = {
    icon: PropTypes.element,
    className: PropTypes.string,
    style: PropTypes.object
}

export default InfoChip;