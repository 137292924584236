import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getEvents} from "../../stories/layout/header/reducer";
import {Button} from "@material-ui/core";
import TrueGrid from "../../theme/components/TrueGrid";
import Header from "../../theme/components/Header";
import PcBottomShit from "../../theme/components/PcBottomShit";
import Img from "../../theme/components/Img";
import {OpenInNew} from "@material-ui/icons";
import useMobile from "../../services/hooks/useMobile";
import {tParams} from "../../theme/Theme";
import Loader from "../../theme/components/Loader";
import EmptyPage from "../../theme/components/EmptyPage";
import "./styles.css"

const Events = (props) => {
    const [open, setOpen] = useState(false)
    const isMobile = useMobile()
    const {data, eventsRef} = props

    const onOpen = () => {
        setOpen(!open)
    }

    function handleClick() {
        onOpen()
    }

    useEffect(()=>{
        props.getEvents()

        if (!eventsRef || !eventsRef?.current) return
        eventsRef.current.addEventListener("click", handleClick)
        return ()=>eventsRef?.current && eventsRef.current.removeEventListener("click", handleClick)
    }, [])


    return (
        <>
            { isMobile?
                <div style={{width: "100%"}}>
                    <div style={{display: "block", height: "80px", width: "100%"}}/>
                </div>:
                <Button color={"primary"} variant={"outlined"} onClick={onOpen} style={{marginLeft: 20, padding: "6px 22px"}}>
                    Мероприятия
                </Button>
            }
            <PcBottomShit open={open} setOpen={setOpen}>
                <TrueGrid gap={isMobile? tParams.mobileCardPadding : tParams.sectionGap} columns={2}>
                    { props.fetching?
                        <Loader className={"mt-5 mb-5"}/>:
                            !data?.length?
                                <EmptyPage/>:
                                data?.map((el, index)=>
                                    <div key={index}>
                                        <a href={el?.attributes?.url} target={"_blank"} className={"hover"} style={{display: "block"}}>
                                            <Header icon={<Img src={el?.attributes?.logo?.data?.attributes?.url} maxHeight={22} style={{borderRadius: 0, minHeight: 22, width: "auto"}}/>}
                                                    h={4}
                                                    title={el?.attributes?.title}
                                                    color={"inherit"}
                                                    className={"mb-5"}
                                            />
                                            <Img item={el} maxHeight={389} className={"mb-5"}/>
                                            <p>{el?.attributes?.text}</p>
                                            <Button color={"primary"} endIcon={<OpenInNew/>} style={{marginTop: 10, marginLeft: -8}} size={"large"}>
                                                На сайт
                                            </Button>
                                        </a>
                                    </div>
                                )
                    }
                </TrueGrid>
            </PcBottomShit>
        </>
    );
};

const mapStateToProps = (state) => ({
    ...state.layout.header
});

export default connect(mapStateToProps,{
    getEvents
})(Events);
