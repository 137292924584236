import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getActivities} from "./reducer";
import Header from "../../../../theme/components/Header";
import TrueGrid from "../../../../theme/components/TrueGrid";
import {makeStyles} from "@material-ui/core/styles";
import {hFont, palette, tParams} from "../../../../theme/Theme";
import {Card} from "@material-ui/core";
import useMobile from "../../../../services/hooks/useMobile";
import EmptyPage from "../../../../theme/components/EmptyPage";
import Loader from "../../../../theme/components/Loader";

const useStyles = makeStyles((theme) => ({
    img:{
        width: 100,
        height: 100,
        display: "block",
        margin: "auto",
    },
    p:{
       // fontSize: 16,
    },
    box:{
        padding: 15,
        width: 70,
        height: 70,
        minWidth: 70,
        borderRadius: 15,
        marginTop: 2,
        background: palette.lightAccent,
        fontSize: 30,
        fontWeight: "bold",
        fontFamily: hFont,
        color: palette.accent,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}))


const Activities = (props) => {
    const classes = useStyles()
    const isMobile = useMobile()

    useEffect(()=>{
        props.getActivities()
    }, [])

    const data = props.data?.attributes
    return (
        <>
            {props.fetching ?
                <Loader/>:
                !data? <EmptyPage/> :
                <TrueGrid className={`mt-${tParams.spacing} pb-5`}>
                    <Header h={1} title={data?.head} style={{justifyContent: "center"}} className={isMobile? "":"mb-6"}/>
                    <span id={"#activities"}/>
                    <TrueGrid columns={2} alignItems={"start"} gap={35} className={"mt-3"}>
                        <TrueGrid gap={35}>
                            {data?.items?.map((el, index) => index < 4 &&
                                <Card className={`flex ${isMobile? "flex-column" : ""}`} style={{gap: isMobile? 20 : 30}} key={index}>
                                    <div className={classes.box}>
                                        <img src={el.media}/>
                                    </div>
                                    <div>
                                        <Header h={5} title={el.head} className={"mb-2"}/>
                                        <p className={classes.p}> {el.text}</p>
                                    </div>
                                </Card>
                            )}
                            {/*!isMobile &&
                                <Link to={CONTACTS_PATH}>
                                    <Button color={"primary"} startIcon={<PersonOutlined style={{fontSize: 25}}/>}
                                            style={{
                                                borderRadius: 50,
                                                padding: "8px 35px",
                                                fontSize: 18,
                                                background: palette.lightAccent
                                            }}>
                                        Контакты для уточнения деталей
                                    </Button>
                                </Link>
                            */}
                        </TrueGrid>
                        <TrueGrid gap={35}>
                            {data?.items?.map((el, index) => index > 3 &&
                                <Card className={`flex ${isMobile? "flex-column" : ""}`} style={{gap: isMobile? 20 : 30}} key={index}>
                                    <div className={classes.box}>
                                        <img src={el.media}/>
                                    </div>
                                    <div>
                                        <Header h={5} title={el.head} className={"mb-2"}/>
                                        <p className={classes.p}> {el.text}</p>
                                    </div>
                                </Card>
                            )}
                        </TrueGrid>
                    </TrueGrid>
                </TrueGrid>
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    ...state.about.activities
});

export default connect(mapStateToProps,{
    getActivities
})(Activities);
