import React from 'react';
import Layout from "../../layout/Layout";
import Hero from "./scenes/hero/Hero";
import Partners from "../about/scenes/partners/Partners";
import {Container} from "@material-ui/core";
import {tParams} from "../../theme/Theme";
import {connect} from "react-redux";
import {getProducts} from "../products/allProducts/reducer";

const Home = (props) => {
    return (
        <Layout title={"Главная"} fetching={props.fetching}>
            <Hero/>
            <Container>
                <div className={`mt-${tParams.spacing}`}>
                    <Partners/>
                </div>
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    fetching: state.home.hero.fetching
});

export default connect(mapStateToProps,{
})(Home);
