import React from 'react';
import PageHeader from "../../layout/header/PageHeader";
import {Container} from "@material-ui/core";
import Layout from "../../layout/Layout";
import About from "./scenes/about/About";
import Partners from "./scenes/partners/Partners";
import Activities from "./scenes/activities/Activities";
import useScrollTo from "../../services/hooks/useScrollTo";
import {connect} from "react-redux";

const AboutPage = (props) => {
    const scroll = useScrollTo([props.aboutTexts?.length], "smooth")

    return (
        <Layout title={"О компании"} fetching={props.fetching}>
            <PageHeader title={"О компании"} center/>
            <Container>
                <span id={"#about"}/>
                <About/>

                <Partners/>

                <Activities/>
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    fetching: state.about.about.fetching,
    aboutTexts: state.about.about.texts,
    partners: state.about.partners.partners,
    activities: state.about.activities.data
});

export default connect(mapStateToProps, null)(AboutPage);
