import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getVacancies} from "./reducer";
import Layout from "../../../../layout/Layout";
import PageHeader from "../../../../layout/header/PageHeader";
import {Container} from "@material-ui/core";
import TrueGrid from "../../../../theme/components/TrueGrid";
import LittleVacancy from "./components/LittleVacancy";
import ContactForm from "../../components/ContactForm";
import Loader from "../../../../theme/components/Loader";
import EmptyPage from "../../../../theme/components/EmptyPage";
import {tParams} from "../../../../theme/Theme";

const Vacancies = (props) => {

    useEffect(()=>{
        props.getVacancies()
    }, [])

    //console.log(props)

    const vacancies = props.data

    return (
        <Layout title={"Вакансии"}  fetching={props.fetching}>
            <PageHeader title={"Вакансии"} center/>
            <Container>
                { props.fetching?
                    <Loader className={"mt-5 mb-5"}/>:
                    !vacancies?.length?
                        <EmptyPage/>:
                        <TrueGrid gap={tParams.sectionGap}>
                            {vacancies?.map(el => <LittleVacancy item={el} key={el?.id}/>)}
                        </TrueGrid>
                }

                <ContactForm/>
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.career.vacancies
});

export default connect(mapStateToProps,{
    getVacancies,
})(Vacancies);
