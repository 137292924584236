export function getDomainUrl(arg) {
    let path = window.location.origin
    return "/"+arg
}

export function downloadFile(path) {
    let name=path.split("/").at(-1)
    fetch(path, {
        method: 'GET',
        headers: new Headers({
            "Authorization": "Bearer " + ""
        })
    })
        .then(response => response.blob())
        .then(blob => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = name
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();  //afterwards we remove the element again
        });
}