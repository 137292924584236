import React, {useEffect, useRef} from 'react';
import {Button} from "@material-ui/core";
import {Link, NavLink, useLocation} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {palette} from "../../theme/Theme";
import useMobile from "../../services/hooks/useMobile";
import {ChevronRight} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    menu:{
        fontSize: 18,
        marginLeft: 15,
        paddingBottom: 3,
        borderRadius: 0,
        transition: "all 0.5s",
        borderBottom: "2px solid transparent",
        "&:hover":{
            background: "transparent",
        },
    },

    active:{
        borderBottom: "2px solid " + palette.accent,
    },
    link:{
        "&:hover":{
            background: "transparent",
            color:  theme.palette.primary.main,
        }
    },

    sub:{
        position: "fixed",
        top: 60,
        borderRadius: 15,
        background: "#fff",
        padding: "8px 0px 8px 0px",
        boxShadow: "0px 10px 20px 0px rgba(0, 00, 80, 0.07)",
        textAlign: "left",
        display: "none"
        //zIndex: 1000,
        //borderTop: '10px solid red',
        //zIndex: 100,
    },
    to: {
        pointerEvents: "auto",
        "&:hover":{
            color:  theme.palette.primary.main,
        }
    },

    subItem: {
        color: "#000",
        fontWeight: 500,
        marginTop: 7,
        padding: '7px 20px',
        "&:hover":{
            background: "#eeeeee",
            color:  theme.palette.primary.main,
        },
    },

    subActive: {
        pointerEvents: "none",
        color: palette.accent
    },

    mobileSub: {
        fontSize: 18,
        fontWeight: 500,
        marginLeft: 30,
        marginTop: -5
    }
}))

const MenuItem = ({to, title, pages, openHandler}) => {
    const classes = useStyles()
    const isMobile = useMobile()

    const ref = useRef(null)
    const childRef = useRef(null)

    const location = useLocation()
    const clickHandler = () => {
        if (openHandler) openHandler()
    }

    let curLocation = location.pathname + location.hash

    useEffect(()=>{
        if (ref.current && childRef.current) {
            if (!isMobile) {
                ref.current.onmouseover = () => {
                    childRef.current.style.display = "block"
                    ref.current.style.borderBottom = "25px solid transparent"
                }
                ref.current.onmouseleave = () => {
                    childRef.current.style.display = "none"
                }
            }
        }
    }, [ref.current , childRef.current])

    const SubIcon = () => {
        if (!pages) return null

        if (!isMobile) {
            return <ChevronRight style={{transform: "rotate(90deg)"}}/>
        }

        return null
    }

    return  (
        <>
            <span ref={ref} >
                <Button className={`${classes.menu} ${curLocation===to ? classes.active :  classes.link}`} endIcon={<SubIcon/>} disableTouchRipple>
                    <NavLink to={to}
                             onClick={clickHandler}
                             className={classes.to}
                    >
                        {title}
                    </NavLink>
                    {pages && !isMobile &&
                        <div className={`${classes.sub} sub-menu`} ref={childRef}>
                            {pages.map(el=>
                                <Link to={el.link} key={el.name}>
                                    <div className={`${classes.subItem} ${curLocation===el.link ? classes.subActive :  ""}`}>
                                        {el.name}
                                    </div>
                                </Link>
                            )}
                        </div>
                    }
                </Button>
            </span>
            {pages && isMobile &&
                <div className={classes.mobileSub}>
                    {pages.map(el=>
                        <NavLink to={el.link} onClick={clickHandler} key={el.name}>
                            <div className={`${classes.subItem} ${curLocation===el.link ? classes.subActive :  ""}`} style={{borderRadius: 50}}>
                                {el.name}
                            </div>
                        </NavLink>
                    )}
                </div>
            }
        </>
    )
}

export default MenuItem;