import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getServices} from "./reducer";
import PageHeader from "../../../layout/header/PageHeader";
import {Container} from "@material-ui/core";
import TrueGrid from "../../../theme/components/TrueGrid";
import Layout from "../../../layout/Layout";
import LittleService from "./components/LittleService";
import {WITH_MEDIA} from "../../../services/const/api";
import Loader from "../../../theme/components/Loader";
import EmptyPage from "../../../theme/components/EmptyPage";
import {tParams} from "../../../theme/Theme";

const AllServices = (props) => {

    useEffect(()=>{
        props.getServices({
            "sort[0]":"id:desc",
            ...WITH_MEDIA
        })
    }, [])

    //console.log(props)

    const services = props.data

    return (
        <Layout title={"Услуги"}  fetching={props.fetching}>
            <PageHeader title={"Услуги"} center/>
            <Container>
                { props.fetching?
                    <Loader/> :
                    !services?.length?
                        <EmptyPage/>:
                        <TrueGrid gap={tParams.sectionGap}>
                            {services?.map(el =>
                                <LittleService item={el} key={el?.id}/>
                            )}
                        </TrueGrid>
                }
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.services.services
});

export default connect(mapStateToProps,{
    getServices
})(AllServices);
