import React, {useEffect, useRef} from 'react';
import "./styles.css"

const Parser = ({text, style, lightBox}) => {
    const ref = useRef(null)

    if (text?.length>0) {
        const codeRegex = /```\n([\w\n]+)\n```/gm;
        text = text.replaceAll(codeRegex, "<code class='parser-code'>$1</code>")

        const blockquoteRegex = /^>(.+)/gm;
        text = text.replaceAll(blockquoteRegex, "<blockquote class='parser-blockquote'>$1</blockquote>")

        const imgRegex = /!\[(.+)\]\((.+)\)/gm;
        text = text.replaceAll(imgRegex, `<a href="#$2"><img src="$2" alt="$1" class="parser-img"/></a>`)

        const aRegex = /\[(.+)\]\((.+)\)/gm;
        text = text.replaceAll(aRegex, `<a target="_blank" href="$2" class="parser-a">$1</a>`)

        const h6Regex = /###### (.+)/gm;
        text = text.replaceAll(h6Regex, "<h6>$1</h6>")

        const h5Regex = /##### (.+)/gm;
        text = text.replaceAll(h5Regex, "<h5>$1</h5>")

        const h4Regex = /#### (.+)/gm;
        text = text.replaceAll(h4Regex, "<h4>$1</h4>")

        const h3Regex = /### (.+)/gm;
        text = text.replaceAll(h3Regex, "<h3>$1</h3>")

        const h2Regex = /## (.+)/gm;
        text = text.replaceAll(h2Regex, "<h2>$1</h2>")

        const h1Regex = /# (.+)/gm;
        text = text.replaceAll(h1Regex, "<h1>$1</h1>")

        const liRegex = /^- (.+)/gm;
        text = text.replaceAll(liRegex, `<li class='parser-li'>$1</li>`)

        const bRegex = /\*\*(.+)\*\*/gm;
        text = text.replaceAll(bRegex, `<b>$1</b>`)

        const iRegex = / _(.+)_ /gm;
        text = text.replaceAll(iRegex, ` <i>$1</i> `)

        const sRegex = /~~(.+)~~/gm;
        text = text.replaceAll(sRegex, `<s>$1</s>`)
    }

    useEffect(()=>{
        if (ref.current)
            ref.current.innerHTML = text
    }, [ref.current, text])

    return (
        <p ref={ref} style={style} id={"parser"}></p>
    );
};

export default Parser;
