import React from 'react';
import {hFont} from "../Theme";

const EmptyPage = (props) => {
    return (
        <div className={`mt-15 mb-15 text-center ${props.className}`} style={props.style}>
            <span style={{fontSize: 35, fontFamily: hFont}}>🥺 <span className={"ml-2"}>Данные отсутствуют</span></span>
        </div>
    );
};

export default EmptyPage;