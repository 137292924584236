import React from 'react';
import {getMedia} from "../../services/functions/functions";
import {makeStyles} from "@material-ui/core/styles";
import {tParams} from "../Theme";
import PropTypes from "prop-types";
import useMobile from "../../services/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    img: {
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: tParams.bigCardRadius,
    },
}))

const BackgroundImg = (props) => {
    const isMobile = useMobile()
    let minHeight = "auto"

    if (isMobile && !props.notUseMobile) minHeight = 300
    if (props.mobileHeight) minHeight = props.mobileHeight

    const classes = useStyles()
    return (
        <div className={`${classes.img} ${props.className}`}
             style={{backgroundImage: `url("${getMedia(props.item)}")`, minHeight: minHeight, ...props.style}}
             onClick={props.onClick}
        />
    );
};

BackgroundImg.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    mobileHeight: PropTypes.number,
    notUseMobile: PropTypes.bool,
}

export default BackgroundImg;