import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { clsx } from "yet-another-react-lightbox";
import { IconButton } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

const SwiperContext = createContext(undefined);

const useSwiper = () => {
  const ctx = useContext(SwiperContext);

  if (ctx === undefined) {
    throw new Error("useSwiper should be used in component wrapped in Swiper");
  }

  return ctx;
};

export const Swiper = ({ children, slidesCount }) => {
  const [active, setActive] = useState(0);

  const increment = () => {
    setActive((prev) => (prev < slidesCount - 1 ? prev + 1 : 0));
  };

  const decrement = () => {
    setActive((prev) => (prev > 0 ? prev - 1 : slidesCount - 1));
  };

  return (
    <SwiperContext.Provider
      value={{ active, set: setActive, decrement, increment, slidesCount }}
    >
      {children}
    </SwiperContext.Provider>
  );
};

Swiper.propTypes = {
  children: PropTypes.node.isRequired,
};

const SwiperContent = ({ children, className, ...props }) => {
  const { active } = useSwiper();

  return (
    <div className={clsx("overflow-hidden", className)}>
      <div
        className="flex transition-transform"
        style={{ transform: `translateX(${active * -100}%)` }}
      >
        {children}
      </div>
    </div>
  );
};

const SwiperSlide = ({ children, className, ...props }) => {
  return (
    <div
      className={clsx("overflow-hidden", className)}
      style={{ minWidth: "100%" }}
      {...props}
    >
      {children}
    </div>
  );
};

const SwiperArrowLeft = () => {
  const { active, decrement } = useSwiper();

  return (
    <IconButton disabled={active === 0} onClick={decrement}>
      <KeyboardArrowLeft/>
    </IconButton>
  );
};

const SwiperArrowRight = () => {
  const { active, increment, slidesCount } = useSwiper();

  return (
    <IconButton disabled={active === slidesCount - 1} onClick={increment}>
      <KeyboardArrowRight />
    </IconButton>
  );
};


Swiper.Content = SwiperContent;
Swiper.Slide = SwiperSlide;
Swiper.ArrowLeft = SwiperArrowLeft;
Swiper.ArrowRight = SwiperArrowRight;
