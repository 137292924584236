import {FAILED, REQUEST, SUCCESS} from "../../services/const";
import {toast} from "react-toastify";
import {call} from "../../services/calls/calls";
import {API_PATH} from "../../services/const/api";

const GET_CONTACTS = "GET_CONTACTS"

const DEFAULT_STATE = {
    fetching: false,
    contacts: {}
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_CONTACTS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_CONTACTS:
            return {
                ...state,
                fetching: false,
                contacts: payload.data.attributes.json || DEFAULT_STATE.contacts
            }

        case FAILED + GET_CONTACTS:
            toast.error("Не удалось получить контакты")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getContacts = () => call(
    'GET',
    `${API_PATH}/contact`,
    GET_CONTACTS,
)