import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {hFont, palette} from "../../theme/Theme";
import {Link, useNavigate} from "react-router-dom";
import {Icon} from "@material-ui/core";
import Bold from "../../theme/components/Bold";
import {PRODUCTS_PATH} from "../../services/router/pages";

const CategoryChip = ({chip, onlyIcon, product, style}) => {
    const navigate= useNavigate()

    const useStyles = makeStyles((theme) => ({
        chip:{
            display: "flex",
            alignItems: "center",
            borderRadius: 50,
            boxShadow: `${palette.chipShadow}`,
            padding: "5px 15px",
            fontSize: 20,
            background: "#fff",
            transition: '0.5s',
            color: "#000",
            "&:hover":{
                color: "inherit",
                boxShadow: `0px 5px 12px 0px ${chip.color?? palette.accent} `
            }
        },
        chipIcon:{
            background: palette.accent,
            borderRadius: 10,
            padding: 7,
            color: "#fff",
            height: 35,
            display: "flex",
            alignItems: "center",
            width: 35,
            justifyContent: "center",
            fontSize: 20,
        },
        colorChip: {
            display: "flex",
            alignItems: "center",
            borderRadius: 50,
            padding: "7px 15px",
            gap: "10px",
            transition: "all 0.5s",
            color: "#fff",
            "&:hover":{
                color: `${chip.color?? palette.accent} !important`,
                boxShadow: `0px 5px 12px 0px ${chip.color?? palette.accent} `,
                background: "#fff !important"
            }
        }
    }))

    const classes = useStyles()

    if (onlyIcon) return (
        <div className={classes.chipIcon} style={{background: chip.color, ...style}}>
            <Icon>{chip.icon  ?? "business"}</Icon>
        </div>
    )

    if (product) return (
        <Link to={`${PRODUCTS_PATH}#${chip?.url}`}>
            <div className={classes.colorChip} style={{background: chip.color ?? palette.accent}}>
                <Icon style={{color: "inherit"}}>{chip.icon ?? "business"}</Icon>
                <Bold style={{fontWeight: "bold", fontFamily: hFont, color: "inherit", ...style}}>{chip.title}</Bold>
            </div>
        </Link>
    )

    return (
        <Link to={chip.link?? ``} style={{color: chip.color}}>
            <div className={classes.chip}>
                <div className={classes.chipIcon} style={{background: chip.color}}>
                    <Icon>{chip.icon}</Icon>
                </div>
                <Bold style={{fontSize: 22, marginLeft: 15, fontWeight: "bold", fontFamily: hFont, color: "inherit", ...style}}>{chip.title}</Bold>
            </div>
        </Link>
    )
}

export default CategoryChip