import React from 'react';
import {palette, tParams} from "../../../../theme/Theme";
import {Box} from "@material-ui/core";
import useMobile from "../../../../services/hooks/useMobile";
import Header from "../../../../theme/components/Header";

const ProductCta = () => {
    const isMobile = useMobile()

    return (
        <Box style={{
            border: palette.border,
            borderWidth: 3,
            borderRadius: tParams.bigCardRadius,
            padding: isMobile? "35px 45px" : "45px 55px",
            marginLeft: isMobile? -25: 0,
            marginRight: isMobile? -25: 0,
            marginTop: "2.5em",
        }}>
            <Header h={4} title={"Хотите узнать больше о данном продукте?"} className={"mb-5"}/>

            <p>
                Наши эксперты всегда готовы проконсультировать по любым вопросам <a href={"mailto:info@neobit.ru"} className="parser-a">info@neobit.ru</a>.
            </p>
        </Box>
    );
};

export default ProductCta;
