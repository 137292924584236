import React from 'react';
import {Button, Container, Grid} from "@material-ui/core";
import Header from "../../../theme/components/Header";
import work from "../../../theme/assets/img/work.gif";
import {makeStyles} from "@material-ui/core/styles";
import {INTERNSHIP_PATH, VACANCY_PATH} from "../../../services/router/pages";
import {Link, useNavigate} from "react-router-dom";
import useMobile from "../../../services/hooks/useMobile";
import {School, Work} from "@material-ui/icons";
import {palette} from "../../../theme/Theme";
import AnimatedText from "../../../stories/animations/AnimatedText";

const useStyles = makeStyles((theme) => ({
    hero:{
        height: "75vh"
    },
    mobileHero:{
        paddingTop: 50,
        paddingBottom: 50,
    },
    img:{
        width: 100,
        height: 100,
        display: "block",
        margin: "auto",
    },
    p:{
        fontSize: 16,
        textAlign: "center",
    },
    btn: {
        fontSize: 20,
        borderRadius: 50,
        padding: "10px 35px"
    }
}))

const TEXTS = [
    "большой",
    "дружной",
    "амбициозной",
];

const CareerHero = (props) => {
    const isMobile = useMobile()
    const classes = useStyles()
    const navigate = useNavigate()

    return (
        <Container>
            <Grid container spacing={3} className={isMobile? classes.mobileHero: classes.hero}>
                <Grid item lg={6} className={"flex items-start flex-column justify-center"} style={isMobile? {}:{marginTop: -40}}>
                    <Header h={0} title={"Стань частью"} />
                    <Header h={0} title={
                        <>
                            <span>нашей </span>
                            { isMobile?
                                <div><AnimatedText words={TEXTS}/></div>:
                                <AnimatedText words={TEXTS}/>
                            }
                        </>
                        }
                    />
                    <Header h={0} title={"команды"} />

                    <div className={"flex"} style={{gap: 30, flexDirection: isMobile? "column" : "row", marginTop: isMobile? 20: 60}} >
                        <Link to={VACANCY_PATH}>
                            <Button startIcon={<Work style={{fontSize: 25}}/>} color={"primary"} size={"large"} className={classes.btn} style={{background: palette.lightAccent}}>
                                Вакансии
                            </Button>
                        </Link>
                        <Link to={INTERNSHIP_PATH}>
                            <Button onClick={()=>navigate(INTERNSHIP_PATH)} startIcon={<School style={{fontSize: 25}}/>} variant={"outlined"} color={"primary"} size={"large"} className={classes.btn}>
                                {isMobile? "Стажировка":"Стажировки и практики"}
                            </Button>
                        </Link>
                    </div>
                </Grid>

                <Grid item lg={6} >
                    <img src={work}/>
                </Grid>
            </Grid>
        </Container>
    );
};

export default CareerHero;