import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Card, Icon} from "@material-ui/core";
import {ArrowForwardIos} from "@material-ui/icons";
import Bold from "./Bold"
import {hFont, palette} from "../Theme";
import PropTypes from "prop-types";
import useMobile from "../../services/hooks/useMobile";

const useStyles = makeStyles(theme => ({
    card:{
        background: palette.back2,
        boxShadow: "none",
        borderRadius: 20,
        padding: 0,
    },
    action:{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        padding: "1.2em",
    },
    iconContainer:{
        borderRadius: 10,
        background: theme.palette.primary.light,
        aspectRatio: "1/1",
        width: 55,
        height: 55,
        display: "flex"
    },
    icon:{
        fontSize: 35,
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    text:{
        margin: "0px 1.3em"
    }
}))

const ActionCard = (props) => {
    const classes = useStyles();
    const isMobile = useMobile()
    return (
        <Card className={`${classes.card} ${props.className}`}>
            <div className={classes.action} onClick={props.onClick}>
                <div className={classes.iconContainer}>
                    <Icon color={"primary"} className={classes.icon}>{props.icon}</Icon>
                </div>

                <div className={classes.text}>
                    <Bold style={{fontSize: 21, fontWeight: 600}}>{props.h}</Bold>
                    <div style={{fontSize: 18, marginTop: 3, color: "gray", fontFamily: hFont}}>{props.text}</div>
                </div>

                { props.right &&
                    <ArrowForwardIos style={{transform: "scale(1.1)", color: "gray", marginLeft: "auto"}}/>
                }
            </div>
        </Card>
    );
};

ActionCard.propTypes = {
    onClick: PropTypes.func,
    right: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    h: PropTypes.string,
    text: PropTypes.string.isRequired,

    className: PropTypes.string,
    style: PropTypes.object
}

export default ActionCard;