import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getVacancy} from "./reducer";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../../../layout/Layout";
import {Container} from "@material-ui/core";
import Header from "../../../../theme/components/Header";
import FlexContainer from "../../../../theme/components/FlexContainer";
import InfoChip from "../../../../theme/components/InfoChip";
import IconChipContent from "../../../../theme/components/IconChipContent";
import {Event} from "@material-ui/icons";
import {parseDateFromStr} from "../../../../services/functions/time";
import Img from "../../../../theme/components/Img";
import {tParams} from "../../../../theme/Theme";
import Parser from "../../../../stories/parser/Parser";
import Breadcrumbs from "../../../../stories/breadcrumbs/Breadcrumbs";
import {CAREER_PATH, VACANCY_PATH} from "../../../../services/router/pages";
import ContactForm from "../../components/ContactForm";
import {BY_URL, WITH_MEDIA} from "../../../../services/const/api";
import Loader from "../../../../theme/components/Loader";
import EmptyPage from "../../../../theme/components/EmptyPage";

const OneVacancy = (props) => {
    const navigate = useNavigate()
    const {vacancyId} = useParams()

    useEffect(()=>{
        props.getVacancy({
            [BY_URL]: vacancyId,
            ...WITH_MEDIA
        })
    }, [])

    const content = props.data?.attributes

    /*if (content?.text === undefined) {
        navigate(SERVICES_PATH)
        toast.error("Данная страница остуствует")
    }*/

    const pages = [
        {link: CAREER_PATH, name: "Карьера"},
        {link: VACANCY_PATH, name: "Вакансии"}
    ]

    return (
        <Layout title={content?.head}  fetching={props.fetching}>
            {/*<PageHeader title={content?.head} center/>*/}
            <Container>
                <Breadcrumbs pages={pages} current={"Вакансия"}/>
                { props.fetching?
                    <Loader className={"mt-5"}/> :
                    !content?
                        <EmptyPage/> :
                        <>
                            <Header h={1} title={content?.head} className={"mt-3 mb-3"}/>
                            <FlexContainer type={"chip"}>
                                <InfoChip>
                                    <IconChipContent icon={<Event/>}>
                                        <b>
                                            <data>{parseDateFromStr(content?.date)}</data>
                                        </b>
                                    </IconChipContent>
                                </InfoChip>
                            </FlexContainer>
                            <Img item={props.data}
                                 maxHeight={400}
                                 border
                                 style={{borderRadius: tParams.bigCardRadius}}
                                 className={"mt-5 mb-7"}
                            />
                            <Parser text={content?.text}/>
                        </>
                }

                <ContactForm/>
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.career.vacancy
});

export default connect(mapStateToProps,{
    getVacancy,
})(OneVacancy);