import React from 'react';
import Header from "../../../theme/components/Header";
import Parser from "../../../stories/parser/Parser";
import {Card} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {palette, tParams} from "../../../theme/Theme";
import useMobile from "../../../services/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    card:{
        background: palette.back2,
        boxShadow: "none",
        marginTop: 50,
        padding: tParams.cardPadding,
        borderRadius: tParams.bigCardRadius
    },
    mobileCard:{
        background: palette.back2,
        boxShadow: "none",
        padding: tParams.mobileCardPadding,
        borderRadius: tParams.bigCardRadius,
        margin: "50px -25px 30px -25px",
    }
}))

const Qualification = ({data}) => {
    const classes = useStyles()
    const isMobile = useMobile()
    return (
        <section className={`mt-${tParams.spacing}`}>
            <Card  className={isMobile? classes.mobileCard: classes.card} id={"qualification"}>
                <Header h={2} title={data?.qualificationTitle} className={"mb-8 justify-center"}/>
                <Parser text={data?.qualification}/>
            </Card>
        </section>
    );
};

export default Qualification;
