import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../../services/calls/calls";
import {API_PATH} from "../../../../services/const/api";

const GET_HERO_CHIPS = "GET_HERO_CHIPS"

const DEFAULT_STATE = {
    fetching: false,
    chips: []
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_HERO_CHIPS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_HERO_CHIPS:
            return {
                ...state,
                fetching: false,
                chips: payload.data.attributes.json || DEFAULT_STATE.chips
            }

        case FAILED + GET_HERO_CHIPS:
            toast.error("Не удалось получить бытсрые действия")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getHero = () => call(
    'GET',
    `${API_PATH}/hero`,
    GET_HERO_CHIPS,
)