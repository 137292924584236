import React from 'react';
import Header from "../../../theme/components/Header";
import TrueGrid from "../../../theme/components/TrueGrid";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {palette, tParams} from "../../../theme/Theme";
import useMobile from "../../../services/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    img:{
        display: "block",
        aspectRatio: "1/1",
        width: "100%"
    },
}))

const CareerWhyAs = ({data}) => {
    const classes = useStyles()
    const isMobile = useMobile()

    return (
        <Box className={""} style={{
            background: palette.back2,
            borderRadius: tParams.bigCardRadius,
            padding: isMobile? tParams.mobileCardPadding : tParams.cardPadding,
            marginLeft: isMobile? -25: 0,
            marginRight: isMobile? -25: 0
        }}>
           <Header h={3} title={data?.cardsTitle} className={"mb-12"}/>
            <TrueGrid columns={2} gap={tParams.sectionGap - 10}>
                { data?.cards?.map(el=>
                    <TrueGrid template={`${isMobile? 40: 60}px auto`} gap={isMobile? 23:30} notUseMobile key={el.media} style={{alignItems: "start"}}>
                        <img src={el.media} className={classes.img}/>
                        <div>
                            <Header h={isMobile? 5:4} className={"mb-4"}>{el.head}</Header>
                            <p  style={{fontSize: isMobile? 18:19}}> {el.text}</p>
                        </div>
                    </TrueGrid>
                )}
            </TrueGrid>
            {/*<Button variant={"contained"} size={"large"} color={"primary"}><Link to={VACANCY_PATH}>Вакансии</Link></Button>*/}
        </Box>
    );
};

export default CareerWhyAs;