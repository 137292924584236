import './App.css';
import "react-toastify/dist/ReactToastify.css";
import Theme from "./theme/Theme";
import {BrowserRouter} from "react-router-dom";
import MyRouter from "./services/router/MyRouter";
import "./theme/styles/_app.scss";
import {Provider} from "react-redux";
import {store} from './services/redux/reducers'
import "./theme/styles/AppStyles.css"
import SiteContainer from "./layout/SiteContainer";

function App() {
  return (
      <Provider store={store}>
          <Theme>
              <BrowserRouter>
                  <SiteContainer>
                      <MyRouter/>
                  </SiteContainer>
              </BrowserRouter>
          </Theme>
      </Provider>
  );
}

export default App;
