import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getContacts} from "./reducer";
import Layout from "../../layout/Layout";
import {Container} from "@material-ui/core";
import Header from "../../theme/components/Header";
import {makeStyles} from "@material-ui/core/styles";
import {Apartment, Map} from "@material-ui/icons";
import FlexContainer from "../../theme/components/FlexContainer";
import ActionCard from "../../theme/components/ActionCard";
import {useNavigate} from "react-router-dom";
import Loader from "../../theme/components/Loader";
import EmptyPage from "../../theme/components/EmptyPage";
import PageHeader from "../../layout/header/PageHeader";
import {tParams} from "../../theme/Theme";
import TopLoader from "../../stories/layout/topLoader/TopLoader";

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: 50,
        alignItems: "center",
    }
}))

const Contacts = (props) => {
    const classes = useStyles()
    let contacts = props.contacts
    const navigate = useNavigate()

    useEffect(()=>{
        props.getContacts()
    }, [])

    return (
        <Layout title={"Контакты"} fetching={props.fetching}>
            <PageHeader title={"Контакты"} center/>
            <Container style={{marginTop: -0}}>
                <Header h={3} title={"Основные контакты"} className={"mb-5"}/>

                { props.fetching?
                    <Loader/>:
                    !contacts?
                        <EmptyPage/>:
                        <>
                            <FlexContainer type={"action"}>
                                <a href={`tel:${contacts.phone}`}>
                                    <ActionCard onClick={null}
                                                icon={"phone"}
                                                h={contacts.phone}
                                                text={"Контактный телефон"}
                                    />
                                </a>
                                <a href={`mailto:${contacts.mail}`}>
                                    <ActionCard onClick={null}
                                                icon={"mail"}
                                                h={contacts.mail}
                                                text={"Электронная почта"}
                                    />
                                </a>
                            </FlexContainer>
                            <Header h={3} title={"Отдел HR"} className={`mt-${tParams.spacing - 10} mb-5`}/>
                            <FlexContainer type={"action"}>
                                <a href={`tel:${contacts.hrPhone}`}>
                                    <ActionCard onClick={null}
                                                icon={"phone"}
                                                h={contacts.hrPhone}
                                                text={"Контактный телефон отдела HR"}
                                    />
                                </a>
                                <a href={`mailto:${contacts.hrMail}`}>
                                    <ActionCard onClick={null}
                                                icon={"mail"}
                                                h={contacts.hrMail}
                                                text={"Электронная почта отдела HR"}
                                    />
                                </a>
                            </FlexContainer>
                            <Header h={3} title={"Для СМИ"} className={`mt-${tParams.spacing - 10} mb-5`}/>
                            <FlexContainer type={"action"}>
                                <a href={`tel:${contacts.smiPhone}`}>
                                    <ActionCard onClick={null}
                                                icon={"phone"}
                                                h={contacts.smiPhone}
                                                text={"Контактный телефон для СМИ"}
                                    />
                                </a>
                                <a href={`mailto:${contacts.smiMail}`}>
                                    <ActionCard onClick={null}
                                                icon={"mail"}
                                                h={contacts.smiMail}
                                                text={"Электронная почта для СМИ"}
                                    />
                                </a>
                            </FlexContainer>

                            <Header h={3} title={"Мы на карте"} className={`mt-${tParams.spacing - 10} mb-5`}/>
                            <Header h={5} icon={<Map/>}
                                    title={<><span style={{color: "gray"}}>Адрес офиса: </span>{contacts.address}</>}
                                    className={"mt-3 mb-7"}/>
                            <Header h={5} icon={<Apartment/>} title={<><span
                                style={{color: "gray"}}>Почтовый адрес: </span>{contacts.urAddress}</>}
                                    className={"mt-3 mb-7"}/>

                            <iframe src={contacts.map} allowFullScreen="true" style={{
                                outline: "none",
                                width: "100%",
                                height: 550,
                                borderRadius: 20,
                                border: "none"
                            }}/>
                        </>
                }
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.contacts
});

export default connect(mapStateToProps,{
    getContacts
})(Contacts);

