import React, {useRef, useState} from 'react';
import "./styles.css"
import MainMenu from "./MainMenu";
import {Button, Container} from "@material-ui/core";
import {CalendarToday, Event} from "@material-ui/icons";

const OpenMenuButton = ({scrollRef}) => {
    const menuRef = useRef(null)
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)

    const eventsRef = useRef(null)

    const [open, setOpen] = useState(false)

    const openHandler = () => {
        if (!open){
            ref1.current.style.animation="selector_1_open 0.5s forwards";
            ref2.current.style.animation="close_list 0.2s forwards";
            ref3.current.style.animation="selector_3_open 0.5s forwards";

            menuRef.current.style.animation="open_list 0.5s forwards";
            menuRef.current.style.display="flex";

            scrollRef.current.style.overflow="hidden"
        } else {
            ref1.current.style.animation="selector_1_close 0.5s forwards";
            ref2.current.style.animation="open_list 0.5s forwards";
            ref3.current.style.animation="selector_3_close 0.5s forwards";

            menuRef.current.style.animation="close_list 0.5s forwards";
            menuRef.current.style.display="none";

            scrollRef.current.style.overflow="auto"
        }
        setOpen(!open)
    }

    return (
        <>
            <Button color={"primary"} style={{fontSize: 12, marginLeft: "auto", marginRight: 10}} ref={eventsRef}>Мероприятия</Button>
            <div id="menu_selector" onClick={openHandler}>
                <div id="selector_1" ref={ref1}/>
                <div  id="selector_2" ref={ref2}/>
                <div  id="selector_3" ref={ref3}/>
            </div>
            <Container id="mobile-menu-container" ref={menuRef}>
                <MainMenu isMobile openHandler={openHandler} eventsRef={eventsRef}/>
            </Container>
        </>
    );
};

export default OpenMenuButton;