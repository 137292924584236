/***
 * Automated Redux state change on Ajax calls. Based on the * @type of action.
 * Method calls API middleware while Fetch API.
 * Implemented to simplify Redux state change.
 * @param method - HTTP request method
 * @param endpoint - URL path to send request
 * @param type - type of Redux method (see constants)
 * @param body - JSON body of the request
 * @param payloadFilter - function to apply for filtering response results
 * @param calls - additional calls to call after response came
 * @return {object} - Redux function to call from Dispatch action
 */
import {FAILED, LOGOUT, REQUEST, SUCCESS} from "../const";
import {CALL_API} from "./apiCalls";


function call(method, endpoint, type, body, payloadFilter, actions, file) {

    const types = [
        REQUEST + type,
        SUCCESS + type,
        FAILED + type
    ];

    let headers={}
    if (!file) {
        headers = {'Content-Type': 'application/json'};
        if (!!body) {
            body = JSON.stringify(body);
            headers['Content-Length'] = body.length.toString();
        }
    }

    return {
        [CALL_API]: {
            endpoint: endpoint,
            types: types,
            config: {
                method,
                headers,
                body,
            },
            payloadFilter,
            actions
        }
    };
}

const logout = () => {

    localStorage.clear();

    return {
        type: LOGOUT,
    }
};

//const getUser = () => call('GET', `${PROFILE_PATH}`, AUTH, null);


/*const setCurrentPage = (payload) => {
    return {
        type: SET_CURRENT_PAGE,
        payload,
    }
};*/


export {
    call,
    //getUser,
    logout,
}