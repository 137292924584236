import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../../services/calls/calls";
import {API_VACANCIES} from "../../../../services/const/api";
import {parseToQuery} from "../../../../services/functions/functions";

const GET_VACANCY = "GET_VACANCY"

const DEFAULT_STATE = {
    fetching: false,
    data: {}
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_VACANCY:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_VACANCY:
            return {
                ...state,
                fetching: false,
                data: payload.data[0] || DEFAULT_STATE.data
            }

        case FAILED + GET_VACANCY:
            toast.error("Не удалось получить вакансию")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getVacancy = (obj) => call(
    'GET',
    `${API_VACANCIES}${parseToQuery(obj)}`,
    GET_VACANCY,
)