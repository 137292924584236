import React, {useEffect} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {hFont} from "../theme/Theme";
import {Container} from "@material-ui/core";
import Header from "../theme/components/Header";
import {connect} from "react-redux";
import {getFooter} from "../stories/layout/footer/reducer";
import {LocationOn, Mail, Phone} from "@material-ui/icons";
import FlexContainer from "../theme/components/FlexContainer";
import logo from "../theme/assets/img/logo.png";
import useMobile from "../services/hooks/useMobile";
import Loader from "../theme/components/Loader";
import EmptyPage from "../theme/components/EmptyPage";

const useStyles = makeStyles((theme) => ({
    footer:{
        marginTop: "100px",
        background: "#f5f5f5",
        padding: "50px 0px",
    },
    menu:{
        marginTop: 5,
        fontFamily: hFont,
        fontSize: 18,
        color: "gray",
    },
    hr:{
        width: "50%",
        height: 1,
        background: "lightgray",
        marginTop: 40,
    }
}))


const Footer = (props) => {
    const classes = useStyles()
    const isMobile = useMobile()

    useEffect(()=>{
        props.getFooter()
    }, [])

    const data = props.data?.attributes
    //console.log(data)

    return (
        <div className={classes.footer}>
            <Container>
                { props.fetching?
                    <Loader/>:
                    !data?
                        <EmptyPage/>:
                        <>
                            <FlexContainer>
                                <img src={logo} style={{width: 190}}/>
                                <Header h={4} title={data?.tagline} style={{transform: "translateY(3px)"}}/>
                            </FlexContainer>
                            <div className={classes.hr}/>
                            <FlexContainer gap={isMobile ? 25 : 50} className={"mt-10"}>
                                <Header h={5} icon={<Phone color={"primary"} style={{fontSize: 32}}/>}>
                                    <a href={`tel:${data?.phone}`} target={"_blank"}>{data?.phone}</a>
                                </Header>
                                <Header h={5} icon={<Mail color={"primary"} style={{fontSize: 32}}/>}>
                                    <a href={`mailto:${data?.email}`} target={"_blank"}>{data?.email}</a>
                                </Header>
                                <Header h={5} icon={<LocationOn color={"primary"} style={{fontSize: 32}}/>}>
                                    <a href={data?.mapLink} target={"_blank"}>{data?.location}</a>
                                </Header>
                            </FlexContainer>
                            <div className={"mt-11"}>{data?.rights}</div>
                        </>
                }
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ...state.layout.footer
});

export default connect(mapStateToProps,{
    getFooter
})(Footer);