import React from 'react';
import TrueGrid from "../../../../theme/components/TrueGrid";
import {Card} from "@material-ui/core";
import {Link} from "react-router-dom";
import Header from "../../../../theme/components/Header";
import {PRODUCTS_PATH} from "../../../../services/router/pages";
import {makeStyles} from "@material-ui/core/styles";
import {palette} from "../../../../theme/Theme";
import {subStr} from "../../../../services/functions/functions";

const useStyles = makeStyles((theme) => ({
    card:{
        cursor: "pointer",
        transition: "0.5s",
        height: "100%",
        "&:hover": {
            color: palette.accent,
            transform: "translateY(-15px)"
        }
    },
    img: {
        width: "100%",
        maxHeight: 80,
        objectFit: "contain",
        marginTop: 10
       // backgroundPosition: "center",
        //backgroundRepeat: "no-repeat",
       // backgroundSize: "contain",
        //borderRadius: tParams.bigCardRadius,
    },
}))

const ProductCards = ({items}) => {
    const classes = useStyles()
    if (!items?.length) return <p>Продукты в данной категории отсутствуют</p>

    function getSortItems(){
        let sorted = items.sort((a, b) => {
            if (a?.attributes?.sortOrder > b?.attributes?.sortOrder) return 1;
            if (a?.attributes?.sortOrder < b?.attributes?.sortOrder) return -1;
            return 0;
        })
        return sorted
    }
    const sortItems = getSortItems()

    return (
        <TrueGrid columns={3} gap={35}>
            { sortItems?.map(el=>
                <Link to={`${PRODUCTS_PATH}/${el?.attributes?.url}`} key={el.id} style={{height: "100%"}}>
                    <Card className={classes.card}>
                        <TrueGrid template={"100px 1fr"}>
                            <img src={el?.attributes?.logoUrl} className={classes.img}/>
                            <div>
                                <Header h={4} title={el?.attributes?.title} className={"mb-2"} color={"inherit"} />
                                <p>{subStr(el?.attributes?.description, 80)}</p>
                            </div>
                        </TrueGrid>
                    </Card>
                </Link>
            )}
        </TrueGrid>
    );
};

export default ProductCards;
