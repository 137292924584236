import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../../services/calls/calls";
import {API_CERT_CATEGORIES, API_PATH, WITH_MEDIA} from "../../../../services/const/api";
import {parseToQuery} from "../../../../services/functions/functions";

const GET_CERTS = "GET_CERTS"
const GET_CERTS_PAGE = "GET_CERTS_PAGE"

const DEFAULT_STATE = {
    fetching: false,
    data: {},
    info: {},
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_CERTS:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_CERTS:
            return {
                ...state,
                fetching: false,
                data: payload.data || DEFAULT_STATE.data
            }

        case FAILED + GET_CERTS:
            toast.error("Не удалось получить информацию о сертификатах")
            return {
                ...state,
                fetching: false,
            }

        case REQUEST + GET_CERTS_PAGE:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_CERTS_PAGE:
            return {
                ...state,
                fetching: false,
                info: payload.data || DEFAULT_STATE.info
            }

        case FAILED + GET_CERTS_PAGE:
            toast.error("Не удалось получить информацию о сертификатах")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getCerts= () => call(
    'GET',
    `${API_CERT_CATEGORIES}/${parseToQuery(WITH_MEDIA)}`,
    GET_CERTS,
)

export const getCertsPage = () => call(
    'GET',
    `${API_PATH}/certs-page`,
    GET_CERTS_PAGE,
)