import React from 'react';
import {getMedia, getMediaAlt} from "../../services/functions/functions";
import {makeStyles} from "@material-ui/core/styles";
import {palette} from "../Theme";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    photo:{
        borderRadius: 15,
        objectFit: "cover",
        //maxHeight: 300,
        width: "100%",
    },
    border: {
       border: palette.border,
    }
}))

const Img = (props) => {
    const classes = useStyles()

    return (
        <img src={props.item? getMedia(props.item): props.src}
             alt={props.item? getMediaAlt(props.item):props.src}
             className={`${classes.photo} ${props.border? classes.border: ""} ${props.className}`}
             style={{maxHeight: props.maxHeight, ...props.style}}
        />
    );
};

Img.propTypes = {
    item: PropTypes.object,
    src: PropTypes.string,
    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    border: PropTypes.bool,

    className: PropTypes.string,
    style: PropTypes.object
}

Img.defaultProps = {
    maxHeight: "auto",
}

export default Img;