import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Header from "../../theme/components/Header";
import {Container} from "@material-ui/core";
import useMobile from "../../services/hooks/useMobile";

const useStyles = makeStyles((theme) => ({
    grad:{
        height: "25vh",
        //background: `url(${briks}) 80% center no-repeat`,
        backgroundSize: "contain",
    },
    mobileGrad:{
        padding: "20px 0px 50px 0px"
    },
    blur:{
        display: "flex",
        alignItems: "center",
        //backdropFilter: 'blur(10px)',
        height: "100%",
        width: "100%",
    },
    head:{
        justifyContent: "center",
    }
}))

const PageHeader = ({title, center}) => {
    const classes = useStyles()
    const isMobile = useMobile()
    return (
        <div className={isMobile? classes.mobileGrad: classes.grad}>
            <div className={classes.blur}>
                <Container>
                    <Header className={center? classes.head : ""} h={0} title={title}/>
                </Container>
            </div>
        </div>
    );
};

export default PageHeader;