import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {getInternship} from "./reducer";
import {CAREER_PATH} from "../../../../services/router/pages";
import Layout from "../../../../layout/Layout";
import {Container} from "@material-ui/core";
import Breadcrumbs from "../../../../stories/breadcrumbs/Breadcrumbs";
import Header from "../../../../theme/components/Header";
import Img from "../../../../theme/components/Img";
import {tParams} from "../../../../theme/Theme";
import Parser from "../../../../stories/parser/Parser";
import ContactForm from "../../components/ContactForm";
import "../../components/styles.css"
import Loader from "../../../../theme/components/Loader";
import EmptyPage from "../../../../theme/components/EmptyPage";

const Internship = (props) => {
    useEffect(()=>{
        props.getInternship()
    }, [])

    const content = props.data?.attributes

    const pages = [
        {link: CAREER_PATH, name: "Карьера"},
    ]

    return (
        <Layout title={content?.head}  fetching={props.fetching}>
            {/*<PageHeader title={content?.head} center/>*/}
            <Container>
                <Breadcrumbs pages={pages} current={"Стражировка"}/>

                { props.fetching?
                    <Loader className={"mt-5 mb-5"}/> :
                    !content?
                        <EmptyPage/>:
                        <>
                            <Header h={1} title={content?.head} className={"mt-4 mb-4"}/>
                            {/*<FlexContainer type={"chip"}>
                            <InfoChip>
                                <IconChipContent icon={<Event/>}>
                                    <b><data>{parseDateFromStr(content?.date)}</data></b>
                                </IconChipContent>
                            </InfoChip>
                        </FlexContainer>*/}
                            <Img item={props.data}
                                 maxHeight={400}
                                 border
                                 style={{borderRadius: tParams.bigCardRadius}}
                                 className={"mt-3 mb-7"}
                            />

                            <div id={"pluses"}>
                                <Parser text={content?.text}/>
                            </div>
                        </>
                }

                <div style={{marginTop: -50}}>
                    <ContactForm/>
                </div>
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.career.internship
});

export default connect(mapStateToProps,{
    getInternship,
})(Internship);
