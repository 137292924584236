import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../../../layout/Layout";
import {Container} from "@material-ui/core";
import Header from "../../../theme/components/Header";
import Img from "../../../theme/components/Img";
import {tParams} from "../../../theme/Theme";
import {connect} from "react-redux";
import {getService} from "./reducer";
import Parser from "../../../stories/parser/Parser";
import {SERVICES_PATH} from "../../../services/router/pages";
import Breadcrumbs from "../../../stories/breadcrumbs/Breadcrumbs";
import {BY_URL, WITH_MEDIA} from "../../../services/const/api";
import Loader from "../../../theme/components/Loader";
import EmptyPage from "../../../theme/components/EmptyPage";

const ServicePage = (props) => {
    const navigate = useNavigate()
    const {serviceId} = useParams()

    useEffect(()=>{
        props.getService({
            [BY_URL]: serviceId,
            ...WITH_MEDIA
        })
    }, [])

    const content = props.data?.attributes

    /*if (content?.text === undefined) {
        navigate(SERVICES_PATH)
        toast.error("Данная страница остуствует")
    }*/

    const pages = [
        {link: SERVICES_PATH, name: "Услуги"},
    ]

    return (
        <Layout title={content?.head}  fetching={props.fetching}>
            {/*<PageHeader title={content?.head} center/>*/}
            <Container>
                <Breadcrumbs pages={pages} current={"Страница услуги"}/>
                { props.fetching?
                    <Loader className={"mt-5"}/> :
                    !content?
                        <EmptyPage/>:
                        <>
                            <Header h={1} title={content?.head} className={"mt-3 mb-1"}/>
                            {/* <FlexContainer type={"chip"}>
                            <InfoChip>
                                <IconChipContent icon={<Event/>}>
                                    <b><data>{parseDateFromStr(content?.date)}</data></b>
                                </IconChipContent>
                            </InfoChip>
                        </FlexContainer>*/}
                            <Img item={props.data}
                                 maxHeight={400}
                                 border
                                 style={{borderRadius: tParams.bigCardRadius}}
                                 className={"mt-5 mb-7"}
                            />
                            <Parser text={content?.text}/>
                        </>
                }
            </Container>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    ...state.services.service
});

export default connect(mapStateToProps,{
    getService
})(ServicePage);

