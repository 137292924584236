import {FAILED, REQUEST, SUCCESS} from "../../../../services/const";
import {toast} from "react-toastify";
import {call} from "../../../../services/calls/calls";
import {API_INTERNSHIP, WITH_MEDIA} from "../../../../services/const/api";
import {parseToQuery} from "../../../../services/functions/functions";

const GET_INTERNSHIP_INFO = "GET_INTERNSHIP_INFO"

const DEFAULT_STATE = {
    fetching: false,
    data: {}
}

export default (state = DEFAULT_STATE, {type, payload}) => {
    switch (type) {
        case REQUEST + GET_INTERNSHIP_INFO:
            return {
                ...state,
                fetching: true,
            }

        case SUCCESS + GET_INTERNSHIP_INFO:
            return {
                ...state,
                fetching: false,
                data: payload.data || DEFAULT_STATE.data
            }

        case FAILED + GET_INTERNSHIP_INFO:
            toast.error("Не удалось получить информацию о стажировках")
            return {
                ...state,
                fetching: false,
            }

        default: {
            return state
        }
    }
}

export const getInternship = () => call(
    'GET',
    `${API_INTERNSHIP}/${parseToQuery(WITH_MEDIA)}`,
    GET_INTERNSHIP_INFO,
)