import React, {useEffect, useLayoutEffect, useRef} from 'react';
import {CircularProgress} from "@material-ui/core";
import Bold from "./Bold";
import PropTypes from "prop-types";
import LoadingBar from "react-top-loading-bar";
import {palette} from "../Theme";

const Loader = (props) => {
    return (
        <div className={`flex items-center justify-center ${props.className}`} style={{gap: 15, margin: "50px auto", ...props.style}}>
            <CircularProgress color={"primary"} size={25}/>
            <Bold>Загрузка...</Bold>
        </div>
    );
};

Loader.propTypes = {

    className: PropTypes.string,
    style: PropTypes.object
}

export default Loader;